import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'adminFichierSlice'


export const adminFichierGetItems = createAsyncThunk(sliceName + "/adminFichierGetItems", async (data, thunkAPI) => {
    try {

        return await axios.get(`admin-fichiers/AdminFichierGetItems`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const adminFichierUpdate = createAsyncThunk(sliceName + "/adminFichierUpdate", async (data, thunkAPI) => {
    try {

        return await axios.patch(`admin-fichiers/AdminFichierUpdateItem`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const adminFichierDeleteItem = createAsyncThunk(sliceName + "/adminFichierDeleteItem", async (data, thunkAPI) => {
    try {

        return await axios.delete(`admin-fichiers/AdminFichierDeleteItem`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


const initialState = {
    isLoading: false,
    fichierItems: null
};

export const adminFichierSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        // adminFichierGetItems
        builder.addCase(adminFichierGetItems.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(adminFichierGetItems.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(adminFichierGetItems.fulfilled, (state, action) => {
            state.isLoading = false;
            state.fichierItems = action.payload.data
        });


        // adminFichierUpdate
        builder.addCase(adminFichierUpdate.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(adminFichierUpdate.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(adminFichierUpdate.fulfilled, (state, action) => {
            state.isLoading = false;
            state.fichierItems = state.fichierItems.map(x => {
                if (x.UniqueId === action.payload.data.UniqueId) {
                    return {
                        ...x,
                        StatutFichier: action.payload.data.StatutFichier
                    }
                }
                return x
            })
        });


        // adminFichierDeleteItem
        builder.addCase(adminFichierDeleteItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(adminFichierDeleteItem.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(adminFichierDeleteItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.fichierItems = state.fichierItems.filter(x => x.UniqueId !== action.payload.data)
        });

    }
});

export const selectIsLoading = (state) => state.adminFichierData.isLoading;
export const selectFichierItems = (state) => state.adminFichierData.fichierItems;

export default adminFichierSlice.reducer;