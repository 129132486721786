
import React from 'react';
import { Link } from 'react-router-dom';
import Config from '../../../Config';
import { FB_URL, TWITTER_URL } from '../../../utils/ConstApp';
import './Footer.css'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="content">
                <div>
                    <h3>Accès</h3>
                    <Link to="/connexion" className="footer-item">Se connecter</Link>
                    <Link to={Config.INSCRIPTION_URL} className="footer-item">S'inscrire</Link>
                    <Link to="/conditions" className="footer-item">Conditions générales</Link>
                </div>
                <div>
                    <h3>Infos utiles</h3>
                    <Link to="/contact" className="footer-item">Nous écrire</Link>
                    <a href={FB_URL} className="footer-item" target="_blank">Facebook</a>
                    <a href={TWITTER_URL} className="footer-item" target="_blank">Twitter</a>
                </div>
                <div>
                    <h3>Nos partenaires</h3>
                    <a className="footer-item" href="https://www.algerie-pratique.com/" target="_blank">Algerie-Pratique.com</a>
                    <a className="footer-item" href="https://docto-pro.com/" target="_blank">Docto-Pro.com</a>
                    <a className="footer-item" href="https://factee.com/" target="_blank">Factee.com</a>
                </div>
            </div>
            <div className="bottom">
                <Link to="/inscription-pro" className="inscription">
                    <strong>Médecin ? Inscrivez-vous !</strong>
                </Link>
                <div className="rights">&copy; 2018 algerie-docto.com</div>
            </div>
        </footer>
    )
}

export default Footer