import "./TableHeader.css";

const TableHeader = ({ children, template, isPadded }) => {
  return (
    <div
      className="table-header"
      style={{
        gridTemplateColumns: template,
        paddingRight: isPadded ? "15px" : "0",
      }}
    >
      {children}
    </div>
  );
};

TableHeader.defaultProps = {
  isPadded: true,
};

export default TableHeader;
