/*############    VENDOR    ##########*/

/*############    APP       ##########*/
import "./TableRow.css";

const TableRow = ({ children, template, height, isEditable, onClick }) => {
  return (
    <div
      className="table-row"
      style={{
        gridTemplateColumns: template,
        gridTemplateRows: height,
        cursor: isEditable ? "pointer" : "initial",
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

TableRow.defaultProps = {
  isEditable: false,
};

export default TableRow;
