import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'proMessageSlice'

export const proGetMessageList = createAsyncThunk(sliceName+"/proGetMessageList", async (data, thunkAPI) => {
    try {
        return await axios.get(`manager-messages`, {
            params: data
        });
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})


export const proAddMessage = createAsyncThunk(sliceName +"/proAddMessage", async (data, thunkAPI) => {
    try {
        return await axios.post(`manager-messages`, data);
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})

export const proDeleteMessage = createAsyncThunk(sliceName +"/proDeleteMessage", async (data, thunkAPI) => {
    try {
        await axios.delete(`manager-messages/${data.idMessage}`);
        return data
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})

const initialState = {
    isLoading: false,
    messageList: null
};

export const proMessageSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: { },
    extraReducers: (builder) => {

        // proGetMessageList
        builder.addCase(proGetMessageList.pending, (state, action) => {
            state.messageList = [];
            state.isLoading = true;
        });
        builder.addCase(proGetMessageList.fulfilled, (state, action) => {
            state.messageList = action.payload.data.List;
            state.isLoading = false;
        });
        builder.addCase(proGetMessageList.rejected, (state, action) => {
            state.isLoading = false;
        });

        // proAddMessage
        builder.addCase(proAddMessage.pending, (state, action) => {
            state.messageList = state.messageList || [];
        });
        builder.addCase(proAddMessage.fulfilled, (state, action) => {
            state.messageList = state.messageList.concat(action.payload.data);
        });

        // proDeleteMessage
        builder.addCase(proDeleteMessage.fulfilled, (state, action) => {
            state.messageList = state.messageList.filter(item => item.IdMessage !== action.payload.idMessage)
        });

    }
});

export const selectMessageList = (state) => state.proMessageData.messageList;
export const selectIsLoading = (state) => state.proMessageData.isLoading;

export default proMessageSlice.reducer;