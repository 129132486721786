import PropTypes from 'prop-types'
import popupStyles from './Popin.module.css'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { useRef } from 'react'

const Popin = ({ children, isVisible, onClose }) => {
  // Comp ref
  const panelRef = useRef()

  // Gestion click outside
  useOnClickOutside(panelRef, () => {
    if (isVisible) {
      console.log('useClickOutside')

      onClose(false)
    }
  })

  return (
    <div
      style={{
        visibility: isVisible ? 'visible' : 'hidden',
        opacity: isVisible ? '1' : '0',
      }}
      className={popupStyles.overlay}
    >
      <div className={popupStyles.popup} ref={panelRef}>
        {children}
      </div>
    </div>
  )
}

Popin.propTypes = {
  children: PropTypes.any,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Popin
