import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './Ellipsis.module.css'

const Ellipsis = ({ children, nbLines }) => {
  var cx = classNames.bind(styles)

  const compClass = cx('ellipsis')

  return (
    <div style={{ WebkitLineClamp: nbLines }} className={compClass}>
      {children}
    </div>
  )
}

Ellipsis.defaultProps = {
  nbLines: 1,
}

Ellipsis.propTypes = {
  children: PropTypes.node.isRequired,
  nbLines: PropTypes.number,
}

export default Ellipsis
