import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'patientUserSlice'

export const patientUserMdpUpdateItem = createAsyncThunk(sliceName + "/patientUserMdpUpdateItem", async (data, thunkAPI) => {
    try {

        return await axios.post(`patient-auth/UserMdpUpdateItem`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


const initialState = {
    isLoading: false,
    isSuccess: false,
    error: null
};

export const patientUserSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        initPatient(state) {
            state.isSuccess = false;
        }
    },
    extraReducers: (builder) => {

        // patientUserMdpUpdateItem
        builder.addCase(patientUserMdpUpdateItem.pending, (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.error = null;
        });
        builder.addCase(patientUserMdpUpdateItem.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.error = action.payload;
        });
        builder.addCase(patientUserMdpUpdateItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
    }
});

// Actions
export const { initPatient } = patientUserSlice.actions

export const selectIsLoadingPatient = (state) => state.patientUserData.isLoading;
export const selectIsSuccessPatient = (state) => state.patientUserData.isSuccess;
export const selectErrorPatient = (state) => state.patientUserData.error;

export default patientUserSlice.reducer;