/*############    VENDOR    ##########*/
import classNames from "classnames";
import "./NoneComponent.css";

/*############    APP       ##########*/

export const variants = Object.freeze({
  Normal: 1,
  Small: 2,
  Big: 3,
});

const NoneComponent = ({ children, variant }) => {
  const componentClass = classNames("none-component", {
    normal: !variant,
    small: variant === variants.Small,
    big: variant === variants.Big,
  });

  return <div className={componentClass}>{children}</div>;
};

export default NoneComponent;
