import './Loader.css'

import { CgSpinner } from '@react-icons/all-files/cg/CgSpinner'

const Loader = ({ color, size, margin }) => {
  return <CgSpinner className='loader' size={size} color={color} style={{ margin }} />
}

Loader.defaultProps = {
  size: 32,
}

export default Loader
