
import { PanelCenter } from 'mga-commons'
import React from 'react'
import { FaLock } from '@react-icons/all-files/fa/FaLock'
import { useSelector } from 'react-redux'
import { Outlet, useLocation, Navigate } from 'react-router-dom'
import ErrorBoundary from '../../components/commons/ErrorBoundary'
import MenuUser from '../../components/commons/MenuUser'
import { selectUserProfile } from '../../slices/anonymous/anonymousAuthSlice'
import './LayoutUser.css'

const LayoutUser = () => {

    const location = useLocation()

    const userProfile = useSelector(selectUserProfile)

    if (!userProfile) {
        return <Navigate replace to="/connexion" />
    }

    const renderContent = () => {

        if (!userProfile.EstActif) {
            return (
                <PanelCenter>

                    <div className="title-lock">
                        <FaLock />&nbsp;
                        Votre compte a été bloqué.
                    </div>
                    <br />
                    <div className="sub-title-lock">
                        Veuillez contacter l'administrateur afin de le débloquer.
                    </div>
                </PanelCenter>
            )
        }
        return <Outlet />
    }

    return (
        <div id="layout-user">
            <MenuUser />
            <div id="pageBody">
                <ErrorBoundary key={location.pathname}>
                    {renderContent()}
                </ErrorBoundary>
            </div>
        </div>
    )
}


export default LayoutUser