import './PanelCenter.css'

import classNames from 'classnames'

const PanelCenter = ({ children, margin, padding, className }) => {
  const compClass = classNames('panel-center', className)

  return (
    <div className={compClass} style={{ margin, padding }}>
      {children}
    </div>
  )
}

export default PanelCenter
