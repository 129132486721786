import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'anonymousStatSlice'

export const anonymousLastSubscribedGetItems = createAsyncThunk(sliceName + "/anonymousLastSubscribedGetItems", async (data, thunkAPI) => {
    try {

        return await axios.get(`statistics/LastSubscribedItems`);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


const initialState = {
    items: null,
};

export const anonymousStatSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: { },
    extraReducers: (builder) => {

        // anonymousLastSubscribedGetItems
        builder.addCase(anonymousLastSubscribedGetItems.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(anonymousLastSubscribedGetItems.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(anonymousLastSubscribedGetItems.fulfilled, (state, action) => {
            state.isLoading = false;
            state.items = action.payload.data;
        });

    }
});


export const selectItems = (state) => state.anonymousStatData.items;

export default anonymousStatSlice.reducer;