import { combineReducers } from 'redux'
import { reducer as toastrReducer } from 'react-redux-toastr'


// ########## ANONYMOUS
import anonymousAuthSlice from "../slices/anonymous/anonymousAuthSlice"
import anonymousStatSlice from "../slices/anonymous/anonymousStatSlice"
import anonymousFicheSlice from "../slices/anonymous/anonymousFicheSlice"
import anonymousFichierSlice from "../slices/anonymous/anonymousFichierSlice"
import anonymousRecoverySlice from "../slices/anonymous/anonymousRecoverySlice"


// ########## PRO
import patientSlice from "../slices/pro/proPatientSlice"
import proFicheSlice from "../slices/pro/proFicheSlice"
import proUserSlice from "../slices/pro/proUserSlice"
import proRdvSlice from "../slices/pro/proRdvSlice"
import proPlanningSlice from "../slices/pro/proPlanningSlice"
import proFichierSlice from "../slices/pro/proFichierSlice"
import proDiscussionSlice from "../slices/pro/proDiscussionSlice"
import proMessageSlice from "../slices/pro/proMessageSlice"


// ########## PATIENT
import patientUserSlice from "../slices/patient/patientUserSlice"
import patientRdvSlice from "../slices/patient/patientRdvSlice"



// ########## ADMIN
import adminFichierSlice from "../slices/admin/adminFichierSlice"
import adminFicheSlice from "../slices/admin/adminFicheSlice"
import adminEmailManagerSlice from "../slices/admin/adminEmailManagerSlice"
import adminUtilisateurSlice from "../slices/admin/adminUtilisateurSlice"


const createRootReducer = (history) => combineReducers({

    // Rooter
    toastr: toastrReducer,

    // Slices : ANONYMOUS
    anonymousAuthData: anonymousAuthSlice,
    anonymousStatData: anonymousStatSlice,
    anonymousFicheData: anonymousFicheSlice,
    anonymousFichierData: anonymousFichierSlice,
    anonymousRecoveryData: anonymousRecoverySlice,

    // Slices : PRO
    proDiscussionData: proDiscussionSlice,
    proMessageData: proMessageSlice,
    proPatientData: patientSlice,
    proFicheData: proFicheSlice,
    proUserData: proUserSlice,
    proRdvData: proRdvSlice,
    proPlanningData: proPlanningSlice,
    proFichierData: proFichierSlice,

    // Slices : PATIENT
    patientUserData: patientUserSlice,
    patientRdvData: patientRdvSlice,

    // Slices : ADMIN
    adminFichierData: adminFichierSlice,
    adminFicheData: adminFicheSlice,
    adminEmailManagerData: adminEmailManagerSlice,
    adminUtilisateurData: adminUtilisateurSlice

})

export default createRootReducer


