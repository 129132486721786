import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/Axios';

const sliceName = 'adminUtilisateurSlice'

export const adminUtilisateurGetList = createAsyncThunk(sliceName + "/adminUtilisateurGetList", async (data, thunkAPI) => {
    try {
        const response = await axios.get(`admin-utilisateurs`, {
            params: data
        });
        return response.data
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

export const adminUtilisateurDeleteItem = createAsyncThunk(sliceName + "/adminUtilisateurDeleteItem", async (data, thunkAPI) => {
    try {
        const response = await axios.delete(`admin-utilisateurs/${data.idUtilisateur}`);
        return response.data
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

const initialState = {

    isLoading: false,
    utilisateurList: null,

};

export const adminUtilisateurSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        // adminUtilisateurGetList
        builder.addCase(adminUtilisateurGetList.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(adminUtilisateurGetList.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(adminUtilisateurGetList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.utilisateurList = action.payload;
        });

        // adminUtilisateurDeleteItem
        builder.addCase(adminUtilisateurDeleteItem.fulfilled, (state, action) => {
            state.utilisateurList = state.utilisateurList.filter(x => x.IdUtilisateur !== action.payload)
        });

    }
});

export const selectIsLoading = (state) => state.adminUtilisateurData.isLoading;
export const selectUtilisateurList = (state) => state.adminUtilisateurData.utilisateurList;

export default adminUtilisateurSlice.reducer;