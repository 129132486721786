import { useState } from "react";

import "./Select.css";

const Select = ({ children, defaultValue, onChange }) => {
  const [selected, setSelected] = useState(defaultValue ?? children[0]);

  const handleChange = (val) => {
    setSelected(val);

    onChange(val);
  };

  return (
    <select
      className="select"
      onChange={(event) => handleChange(parseInt(event.target.value))}
      value={selected}
    >
      {children.map((item, index) => {
        return (
          <option key={index} className="select-option" value={item.value}>
            {item.name}
          </option>
        );
      })}
    </select>
  );
};

export default Select;
