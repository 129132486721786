/*############    VENDOR    ##########*/

/*############    APP       ##########*/
import "./PageSummaryItem.css";

export const variants = Object.freeze({
  Inverted: 0,
});

const PageSummaryItem = ({ label, value, variant }) => {
  if (variant === variants.Inverted) {
    return (
      <div className="page-summary-item">
        <span className="page-summary-item-label">{label}</span>
        <span className="page-summary-item-value">{value}</span>
      </div>
    );
  }

  return (
    <div className="page-summary-item">
      <span className="page-summary-item-value">{value}</span>
      <span className="page-summary-item-label">{label}</span>
    </div>
  );
};

export default PageSummaryItem;
