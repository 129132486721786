import { BsFillPersonFill } from '@react-icons/all-files/bs/BsFillPersonFill';
import { BsPerson } from '@react-icons/all-files/bs/BsPerson';
import { FaPowerOff } from '@react-icons/all-files/fa/FaPowerOff';
import { Btn, BtnVariants } from 'mga-commons';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { selectUserProfile } from '../../../slices/anonymous/anonymousAuthSlice';
import { EnumProfil } from '../../../utils/EnumApp';
import './HeaderTop.css';


const renderLogin = (userProfile, location) => {

    const isLogin = location.pathname === '/connexion'

    if (userProfile) {

        const lien = userProfile?.Profil === EnumProfil.Admin.value
            ? '/manager/admin/accueil'
            : userProfile?.Profil === EnumProfil.Pro.value
                ? '/manager/pro/accueil'
                : '/manager/patient/accueil'

        return (
            <>
                <Link to="/connexion" className="disconnect">
                    Se déconnecter&nbsp;
                    <FaPowerOff size="16" />
                </Link>
                <Link to={lien} className="login">
                    Gérer mon compte
                    <BsPerson size="26" />
                </Link>
            </>
        )
    }

    if (isLogin) {
        return null
    }

    return (
        <Link to="/connexion" className="login">
            Se connecter
            <BsFillPersonFill />
        </Link>
    )

}

export const HeaderTop = ({ }) => {

    const userProfile = useSelector(selectUserProfile)

    const navigate = useNavigate()

    const location = useLocation();

    const isInscription = location.pathname === '/inscription'

    const isInscriptionPro = location.pathname === '/inscription-pro'


    return (
        <div className="header-top">
            {
                !isInscriptionPro && !userProfile &&
                <Btn onClick={() => navigate('/inscription-pro')} variant={BtnVariants.SecondaryOutline} margin="auto auto auto 10px" padding="0 10px" maxWidth="240px">
                    <strong>Professionnel? Inscrivez-vous !</strong>
                </Btn>
            }
            {
                !isInscription && !userProfile &&
                <Btn onClick={() => navigate('/inscription')} margin="auto 10px auto auto" padding="0 10px" maxWidth="160px">
                    <strong>Inscription patient</strong>
                </Btn>
            }
            {renderLogin(userProfile, location)}
        </div>
    )
}

export default HeaderTop
