import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'adminFicheSlice'

// La même route que la multi mais gestion différente
export const adminFicheGetItem = createAsyncThunk(sliceName + "/adminFicheGetItem", async (data, thunkAPI) => {
    try {

        return await axios.get(`admin-fiches/AdminFicheGetItems`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const adminFicheGetItems = createAsyncThunk(sliceName + "/adminFicheGetItems", async (data, thunkAPI) => {
    try {

        return await axios.get(`admin-fiches/AdminFicheGetItems`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const adminFicheSaveOriginal = createAsyncThunk(sliceName + "/adminFicheSaveOriginal", async (data, thunkAPI) => {
    try {

        return await axios.post(`admin-fiches/AdminFicheSaveItem`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const adminFicheDeleteCopy = createAsyncThunk(sliceName + "/adminFicheDeleteCopy", async (data, thunkAPI) => {
    try {

        return await axios.delete(`admin-fiches/AdminFicheDeleteCopy`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


const initialState = {
    isLoadingItems: false,
    ficheItems: null,

    isLoading: false,
    fiche: null,
};

export const adminFicheSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        // adminFicheGetItem
        builder.addCase(adminFicheGetItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(adminFicheGetItem.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(adminFicheGetItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.fiche = action.payload.data[0] 
        });

        // adminFicheGetItems
        builder.addCase(adminFicheGetItems.pending, (state, action) => {
            state.isLoadingItems = true;
        });
        builder.addCase(adminFicheGetItems.rejected, (state, action) => {
            state.isLoadingItems = false;
        });
        builder.addCase(adminFicheGetItems.fulfilled, (state, action) => {
            state.isLoadingItems = false;
            state.ficheItems = action.payload.data
        });


        // adminFicheSaveOriginal
        builder.addCase(adminFicheSaveOriginal.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(adminFicheSaveOriginal.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(adminFicheSaveOriginal.fulfilled, (state, action) => {
            state.isLoading = false;
        });

        // adminFicheDeleteCopy
        builder.addCase(adminFicheDeleteCopy.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(adminFicheDeleteCopy.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(adminFicheDeleteCopy.fulfilled, (state, action) => {
            state.isLoading = false;
        });

    }
});

export const selectIsLoadingItems = (state) => state.adminFicheData.isLoadingItems;
export const selectFicheItems = (state) => state.adminFicheData.ficheItems;

export const selectIsLoading = (state) => state.adminFicheData.isLoading;
export const selectFiche = (state) => state.adminFicheData.fiche;

export default adminFicheSlice.reducer;