import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'proDiscussionSlice'

export const progetDiscussionList = createAsyncThunk(sliceName+"/progetDiscussionList", async (data, thunkAPI) => {
    try {
        return await axios.get(`manager-discussions`);
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})

export const proAddDiscussion = createAsyncThunk(sliceName +"/proAddDiscussion", async (data, thunkAPI) => {
    try {
        return await axios.post(`manager-discussions`, data);
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})

export const proDeleteDiscussion = createAsyncThunk(sliceName +"/proDeleteDiscussion", async (data, thunkAPI) => {
    try {
        await axios.delete(`manager-discussions/${data.idDiscussion}`);
        return data
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})

const initialState = {
    isLoading: false,
    discussionList: null
};

export const proDiscussionSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: { },
    extraReducers: (builder) => {

        // progetDiscussionList
        builder.addCase(progetDiscussionList.pending, (state, action) => {
            state.discussionList = [];
            state.isLoading = true;
        });
        builder.addCase(progetDiscussionList.fulfilled, (state, action) => {
            state.discussionList = action.payload.data.List;
            state.isLoading = false;
        });
        builder.addCase(progetDiscussionList.rejected, (state, action) => {
            state.isLoading = false;
        });

        // proAddDiscussion
        builder.addCase(proAddDiscussion.pending, (state, action) => {
            state.discussionList = state.discussionList || [];
            state.isLoading = true;
        });
        builder.addCase(proAddDiscussion.fulfilled, (state, action) => {
            state.discussionList = state.discussionList.concat(action.payload.data);
            state.isLoading = false;
        });
        builder.addCase(proAddDiscussion.rejected, (state, action) => {
            state.isLoading = false;
        });

        // proDeleteDiscussion
        builder.addCase(proDeleteDiscussion.fulfilled, (state, action) => {
            state.discussionList = state.discussionList.filter(item => item.IdDiscussion !== action.payload.idDiscussion)
        });

    }
});

export const selectDiscussionList = (state) => state.proDiscussionData.discussionList;
export const selectIsLoading = (state) => state.proDiscussionData.isLoading;

export default proDiscussionSlice.reducer;