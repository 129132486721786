import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'adminEmailManagerSlice'


// ########### CONF 

export const adminEmailConfGetItems = createAsyncThunk(sliceName + "/adminEmailConfGetItems", async (data, thunkAPI) => {
    try {

        return await axios.get(`admin-email-manager/conf`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const adminEmailConfDeleteItem = createAsyncThunk(sliceName + "/adminEmailConfDeleteItem", async (data, thunkAPI) => {
    try {

        return await axios.delete(`admin-email-manager/conf/${data.idToDelete}`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const adminEmailConfAddItem = createAsyncThunk(sliceName + "/adminEmailConfAddItem", async (data, thunkAPI) => {
    try {

        return await axios.post(`admin-email-manager/conf`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const adminEmailConfUpdateItem = createAsyncThunk(sliceName + "/adminEmailConfUpdateItem", async (data, thunkAPI) => {
    try {

        return await axios.patch(`admin-email-manager/conf`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})



/// ###############  HISTORY


export const adminEmailHistoryGetItems = createAsyncThunk(sliceName + "/adminEmailHistoryGetItems", async (data, thunkAPI) => {
    try {

        return await axios.get(`admin-email-manager/history`, data);

    } catch (error) {

        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})



/// ###############  TEMPLATE

export const adminEmailTemplateGetItems = createAsyncThunk(sliceName + "/adminEmailTemplateGetItems", async (data, thunkAPI) => {
    try {

        return await axios.get(`admin-email-manager/templates`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const adminEmailTemplateDeleteItem = createAsyncThunk(sliceName + "/adminEmailTemplateDeleteItem", async (data, thunkAPI) => {
    try {

        return await axios.delete(`admin-email-manager/templates/${data.idToDelete}`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const adminEmailTemplateAddItem = createAsyncThunk(sliceName + "/adminEmailTemplateAddItem", async (data, thunkAPI) => {
    try {

        return await axios.post(`admin-email-manager/templates`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const adminEmailTemplateUpdateItem = createAsyncThunk(sliceName + "/adminEmailTemplateUpdateItem", async (data, thunkAPI) => {
    try {

        return await axios.patch(`admin-email-manager/templates`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})



/// ###############  SEND

export const adminEmailSend = createAsyncThunk(sliceName + "/adminEmailSend", async (data, thunkAPI) => {
    try {

        return await axios.post(`admin-email-manager/send`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

const initialState = {

    isLoading: false,
    isEnabled: true,

    confItems: null,
    confLightItems: null,
    historyItems: null,
    templateItems: null,
};

export const adminEmailManagerSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {


        // ##################  CONF 

        // adminEmailConfGetItems
        builder.addCase(adminEmailConfGetItems.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(adminEmailConfGetItems.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(adminEmailConfGetItems.fulfilled, (state, action) => {
            state.isLoading = false;
            state.confItems = action.payload.data
            state.confLightItems = action.payload.data.map(x => {
                return {
                    IdEmailManagerConfiguration: x.IdEmailManagerConfiguration,
                    Label: x.SmtpServer + x.SmtpPort
                }
            })
        });

        // adminEmailConfAddItem
        builder.addCase(adminEmailConfAddItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(adminEmailConfAddItem.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(adminEmailConfAddItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.confItems = [...state.confItems, action.payload.data]
        });

        // adminEmailConfDeleteItem
        builder.addCase(adminEmailConfDeleteItem.pending, (state, action) => {
            state.isEnabled = false;
        });
        builder.addCase(adminEmailConfDeleteItem.rejected, (state, action) => {
            state.isEnabled = true;
        });
        builder.addCase(adminEmailConfDeleteItem.fulfilled, (state, action) => {
            state.isEnabled = true;
            state.confItems = state.confItems.filter(x => x.IdEmailManagerConfiguration !== action.payload.data) 
        });

        // adminEmailConfUpdateItem
        builder.addCase(adminEmailConfUpdateItem.pending, (state, action) => {
            state.isEnabled = false;
        });
        builder.addCase(adminEmailConfUpdateItem.rejected, (state, action) => {
            state.isEnabled = true;
        });
        builder.addCase(adminEmailConfUpdateItem.fulfilled, (state, action) => {
            state.isEnabled = true;
            state.confItems = state.confItems.map(x => {
                if (x.IdEmailManagerConfiguration === action.payload.data.IdEmailManagerConfiguration) {
                    return action.payload.data
                }
                return x
            })
        });



        // ##################  TEMPLATES

        // adminEmailTemplateGetItems
        builder.addCase(adminEmailTemplateGetItems.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(adminEmailTemplateGetItems.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(adminEmailTemplateGetItems.fulfilled, (state, action) => {
            state.isLoading = false;
            state.templateItems = action.payload.data
        });

        // adminEmailTemplateAddItem
        builder.addCase(adminEmailTemplateAddItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(adminEmailTemplateAddItem.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(adminEmailTemplateAddItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.templateItems = [...state.templateItems, action.payload.data]
        });

        // adminEmailTemplateDeleteItem
        builder.addCase(adminEmailTemplateDeleteItem.pending, (state, action) => {
            state.isEnabled = false;
        });
        builder.addCase(adminEmailTemplateDeleteItem.rejected, (state, action) => {
            state.isEnabled = true;
        });
        builder.addCase(adminEmailTemplateDeleteItem.fulfilled, (state, action) => {
            state.isEnabled = true;
            state.templateItems = state.templateItems.filter(x => x.IdEmailManagerTemplate !== action.payload.data)
        });

        // adminEmailTemplateUpdateItem
        builder.addCase(adminEmailTemplateUpdateItem.pending, (state, action) => {
            state.isEnabled = false;
        });
        builder.addCase(adminEmailTemplateUpdateItem.rejected, (state, action) => {
            state.isEnabled = true;
        });
        builder.addCase(adminEmailTemplateUpdateItem.fulfilled, (state, action) => {
            state.isEnabled = true;
            state.templateItems = state.templateItems.map(x => {
                if (x.IdEmailManagerTemplate === action.payload.data.IdEmailManagerTemplate) {
                    return action.payload.data
                }
                return x
            })
        });


        // ##################  HISTORY

        // adminEmailHistoryGetItems
        builder.addCase(adminEmailHistoryGetItems.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(adminEmailHistoryGetItems.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(adminEmailHistoryGetItems.fulfilled, (state, action) => {
            state.isLoading = false;
            state.historyItems = action.payload.data
        });

    }
});

export const selectConfItems = (state) => state.adminEmailManagerData.confItems;
export const selectConfLightItems = (state) => state.adminEmailManagerData.confLightItems;

export const selectHistoryItems = (state) => state.adminEmailManagerData.historyItems;
export const selectTemplateItems = (state) => state.adminEmailManagerData.templateItems;

export const selectIsLoading = (state) => state.adminEmailManagerData.isLoading;
export const selectIsEnabled = (state) => state.adminEmailManagerData.isEnabled;


export default adminEmailManagerSlice.reducer;