import { useMediaQuery } from "react-responsive"

export const useMinTabletQuery = () => useMediaQuery({ minWidth: 768 })

export const useMaxTabletQuery = () => useMediaQuery({ maxWidth: 991 })

export const useMobileQuery = () => useMediaQuery({ maxWidth: 767 })

export const useMinDesktopQuery = () => useMediaQuery({ minWidth: 992 })

export const MinDesktop = ({ children }) => {

    const isMinDesktop = useMinDesktopQuery()

    return isMinDesktop ? children : null
}

export const MaxTablet = ({ children }) => {

    const isMaxTablet = useMaxTabletQuery()

    return isMaxTablet ? children : null
}

export const MinTablet = ({ children }) => {

    const isMinTablet = useMinTabletQuery()

    return isMinTablet ? children : null
}

export const Mobile = ({ children }) => {

    const isMobile = useMobileQuery()

    return isMobile ? children : null
}