import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'
import { SendUserId } from '../../utils/AnalyticsUtils'
import { GetProfileFromStorage, RemoveProfileFromStorage, SaveProfileToStorage } from '../../utils/AuthUtils';

const sliceName = 'anonymousAuthSlice'

export const anonymousSendEmail = createAsyncThunk(sliceName + "/anonymousSendEmail", async (data, thunkAPI) => {
    try {

        return await axios.post(`auth/SendEmail`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const anonymousSubscribe = createAsyncThunk(sliceName + "/anonymousSubscribe", async (data, thunkAPI) => {
    try {

        return await axios.post(`auth/UserSubscribe`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const anonymousLogin = createAsyncThunk(sliceName + "/anonymousLogin", async (data, thunkAPI) => {
    try {

        return await axios.post(`auth/UserLogin`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


const initialState = {
    useProfile: GetProfileFromStorage(),

    isLoading: null,
    isSuccess: null,

    error: null,

};

export const anonymousAuthSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        initAnonymous(state) {
            state.isLoading = null;
            state.isSuccess = null;
            state.error = null;
        },
        disconnect(state) {

            // Remove from storage
            RemoveProfileFromStorage()

            // UserId
            SendUserId(null, null)

            state.useProfile = null;
        }
    },
    extraReducers: (builder) => {

        // anonymousSendEmail
        builder.addCase(anonymousSendEmail.pending, (state, action) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(anonymousSendEmail.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.error = action.payload;
        });
        builder.addCase(anonymousSendEmail.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
        });

        // anonymousSubscribe
        builder.addCase(anonymousSubscribe.pending, (state, action) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(anonymousSubscribe.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(anonymousSubscribe.fulfilled, (state, action) => {
            state.isLoading = false;
            state.useProfile = action.payload.data

            // Save to storage
            SaveProfileToStorage(action.payload.data)
        });

        // anonymousLogin
        builder.addCase(anonymousLogin.pending, (state, action) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(anonymousLogin.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(anonymousLogin.fulfilled, (state, action) => {
            state.isLoading = false;
            state.useProfile = action.payload.data

            // Save to storage
            SaveProfileToStorage(action.payload.data)
        });

    }
});

// Actions
export const { disconnect, initAnonymous } = anonymousAuthSlice.actions


export const selectUserProfile = (state) => state.anonymousAuthData.useProfile;

export const selectIsLoading = (state) => state.anonymousAuthData.isLoading;
export const selectIsSuccess = (state) => state.anonymousAuthData.isSuccess;
export const selectError = (state) => state.anonymousAuthData.error;

export default anonymousAuthSlice.reducer;