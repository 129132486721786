
import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import ErrorBoundary from '../components/commons/ErrorBoundary'
import Footer from '../components/commons/Footer/Footer'
import Header from '../components/commons/Header'


const LayoutPage = () => {

    const location = useLocation();

    return (
        <div id="pageContainer">
            <Header />
            <div id="pageBody">
                <ErrorBoundary key={location.pathname }>
                    <Outlet />
                </ErrorBoundary>
            </div>
            <Footer />
        </div>
    )
}


export default LayoutPage