/*############    VENDOR    ##########*/

/*############    APP       ##########*/
import "./TabContent.css";

const TabContent = ({ children }) => {
  return <div className="tab-content">{children}</div>;
};

export default TabContent;
