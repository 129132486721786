import './NotifFatal.css'

const NotifFatal = ({ title, message }) => {
  return (
    <div className='notif-fatal'>
      <div className='title'>{title}</div>
      <div className='message'>{message}</div>
    </div>
  )
}

export default NotifFatal
