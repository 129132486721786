import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'proPatientSlice'

export const proGetPatientAsync = createAsyncThunk(sliceName + '/proGetPatientAsync', async (data, thunkAPI) => {
    try {
        return await axios.get(`manager-patients`, {
            params: data
        });

    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})

export const proGetPatientsAsync = createAsyncThunk(sliceName + '/proGetPatientsAsync', async (data, thunkAPI) => {
    try {
        return await axios.get(`manager-patients`, {
            params: data
        });

    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})

export const proAddPatientAsync = createAsyncThunk(sliceName + '/proAddPatientAsync', async (data, thunkAPI) => {
    try {
        return await axios.post(`manager-patients`, data);

    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})

export const proSavePatientAsync = createAsyncThunk(sliceName + '/proSavePatientAsync', async (data, thunkAPI) => {
    try {
        await axios.patch(`manager-patients`, data);
        return data

    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})

export const proDeletePatientAsync = createAsyncThunk(sliceName + '/proDeletePatientAsync', async (data, thunkAPI) => {

    try {
        await axios.delete(`manager-patients`, data);

        return thunkAPI.fulfillWithValue(data.params.IdPatient)

    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})

const initialState = {
    item: {},
    items: null,
    isLoading: false
};

export const proPatientSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        // proGetPatientsAsync
        builder.addCase(proGetPatientAsync.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(proGetPatientAsync.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(proGetPatientAsync.fulfilled, (state, action) => {
            state.item = action.payload.data[0];
            state.isLoading = false;
        });

        // proGetPatientsAsync
        builder.addCase(proGetPatientsAsync.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(proGetPatientsAsync.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(proGetPatientsAsync.fulfilled, (state, action) => {
            state.items = action.payload.data;
            state.isLoading = false;
        });

        // proAddPatientAsync
        builder.addCase(proAddPatientAsync.pending, (state, action) => {
            state.items = state.items || [];
            state.isLoading = true;
        });
        builder.addCase(proAddPatientAsync.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(proAddPatientAsync.fulfilled, (state, action) => {
            state.items = state.items.concat(action.payload.data);
            state.isLoading = false;
        });

        // proSavePatientAsync
        builder.addCase(proSavePatientAsync.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(proSavePatientAsync.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(proSavePatientAsync.fulfilled, (state, action) => {
            state.isLoading = false;
        });

        // proDeletePatientAsync
        builder.addCase(proDeletePatientAsync.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(proDeletePatientAsync.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(proDeletePatientAsync.fulfilled, (state, action) => {
            state.isLoading = false;
            state.items = state.items.filter(item => item.IdPatient === action.payload)
        });

    }
});

export const selectPatient = (state) => state.proPatientData.item;
export const selectPatientItems = (state) => state.proPatientData.items;
export const selectIsLoading = (state) => state.proPatientData.isLoading;

export default proPatientSlice.reducer;