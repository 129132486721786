//import dayjs from 'dayjs';
//import 'dayjs/locale/fr';
//import customParseFormat from 'dayjs/plugin/customParseFormat';
//import relativeTime from 'dayjs/plugin/relativeTime';
import React, { Component } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import ReduxToastr from "react-redux-toastr";
import configureStore from './configureStore';
import MyRouter from './MyRouter';
import { Init } from "./utils/AnalyticsUtils";

import("./css/init.css")
import("./css/react-redux-toastr.css")
import("./css/site.css")
import("react-image-lightbox/style.css")

/*########################################*/
/*############    INITIALIZE    ##########*/
/*########################################*/

// Store
const store = configureStore()


// Init Google Analytics
Init()

// dayjs
//dayjs.locale('fr')
//dayjs.extend(relativeTime)
//dayjs.extend(customParseFormat)

/*############    PROTOTYPES       ##########*/

String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim());
}

String.prototype.capitalizeFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

String.prototype.lowerFirstLetter = function () {
    return this.charAt(0).toLowerCase() + this.slice(1);
}

String.prototype.CapitalizeFirst = function () {
    return this.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
}

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <ReduxProvider store={store}>
                <ReduxToastr position="bottom-right" timeOut={2500} progressBar={true} />
                <MyRouter />
            </ReduxProvider >
        );
    }
}
