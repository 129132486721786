import './InputSearch.css'

import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import { FaCaretDown } from '@react-icons/all-files/fa/FaCaretDown'
import { FaCaretRight } from '@react-icons/all-files/fa/FaCaretRight'
import { FaSearch } from '@react-icons/all-files/fa/FaSearch'
import InputText from '../InputText/InputText'
import Loader from '../Loader'
import Popover from '../Popover'
import PropTypes from 'prop-types'
import { VscClose } from '@react-icons/all-files/vsc/VscClose'

const renderContent = (items, getLabel, onItemSelected) => {
  if (!items || items.length < 1) {
    return (
      <div className='none-selected'>
        Aucun entrée trouvée.
        <br /> Veuillez essayer une autre recheche
      </div>
    )
  }

  return items.map((item, index) => (
    <div
      className='item'
      key={index}
      title={getLabel(item)}
      onClick={() => (onItemSelected ? onItemSelected(item) : undefined)}
    >
      {getLabel(item)}
    </div>
  ))
}

const cleanFunc = (text) =>
  text && text.length > 0
    ? text
        .toLowerCase()
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
    : ''

const InputSearch = ({
  text,
  items,
  placeholder,
  margin,
  padding,
  minWidth,
  maxWidth,
  limit,
  offset,
  timeout,
  isSelectOnly,
  isEnabled,
  isLoading,
  getLabel,
  onSelect,
  onRequestLoad,
}) => {
  const refInput = useRef(null)

  const [width, setWidth] = useState(0)
  const [currentTimeout, seCurrentTimeout] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const [filtered, setFiltered] = useState([])
  const [currentText, setCurrentText] = useState('')
  const [isModified, setIsModified] = useState(false)

  useLayoutEffect(() => {
    const width = refInput.current.clientWidth

    // Décalage de la liste
    setWidth(width - 2)
  }, [refInput?.current?.clientWidth])

  useEffect(() => {
    // Request reload
    if (!isSelectOnly) {
      reloadData(text || '')
    }

    setCurrentText(text)
  }, [text])

  useEffect(() => {
    // Update filtered
    updateFiltered()
  }, [currentText])

  useEffect(() => {
    // Update filtered
    updateFiltered()
  }, [items])

  const updateFiltered = () => {
    // Copy
    let copy = [...(items || [])]

    if (currentText && currentText.length > 0 && !isSelectOnly) {
      // Clean string
      const cleanFilterText = cleanFunc(currentText)

      copy = copy.filter((item) => cleanFunc(getLabel(item)).indexOf(cleanFilterText) > -1)
    }

    // Limit
    if (!isSelectOnly && limit) {
      copy = copy.splice(0, limit)
    }

    // Update filtered
    setFiltered(copy)
  }

  const reloadData = (inputValue) => {
    // If onRequestLoad is defined
    if (onRequestLoad) {
      clearTimeout(currentTimeout)

      seCurrentTimeout(null)

      let inputValueParam = inputValue

      seCurrentTimeout(
        setTimeout(() => {
          // Request reload
          onRequestLoad(inputValueParam)
        }, timeout)
      )
    }
  }

  const handleInputChange = (newValue) => {
    // Update value
    if (isEnabled && !isSelectOnly) {
      // Request reload
      if (!isSelectOnly) {
        reloadData(newValue || '')
      }

      setCurrentText(newValue)

      setIsModified(true)
    }
  }

  const handleItemSelected = (item) => {
    setIsVisible(false)

    setCurrentText(getLabel(item))

    // Notify
    onSelect(item)
  }

  const showTippy = (val) => {
    setIsVisible(val)
  }

  const handleDeleteText = () => {
    setIsVisible(false)

    setCurrentText('')

    // Notify
    onSelect && onSelect(null)

    setIsModified(false)
  }

  const handleBlur = () => {
    if (isModified && text !== currentText) {
      // Notify
      onSelect && onSelect(null)
    }
  }

  const renderInputIcons = () => {
    if (isLoading) {
      return <Loader size={28} color='#325288' margin='auto 4px' />
    }

    if (currentText && currentText.length > 0) {
      return <VscClose className='icon-cancel' onClick={isEnabled ? handleDeleteText : undefined} size={30} />
    }

    return null
  }

  const renderInput = () => {
    if (isSelectOnly) {
      return (
        <div
          className='select-only-container'
          style={{ maxWidth, minWidth, padding }}
          onClick={() => setIsVisible(!isVisible)}
        >
          {isVisible ? (
            <FaCaretDown style={{ paddingRight: '12px' }} size={32} />
          ) : (
            <FaCaretRight style={{ paddingRight: '12px' }} size={32} />
          )}
          {!currentText || currentText.length < 1 ? (
            <span style={{ color: '#959595', fontWeight: 'normal' }}>{placeholder}</span>
          ) : (
            <span>{currentText}</span>
          )}
          {currentText && currentText.length > 0 && (
            <VscClose className='icon-cancel' onClick={isEnabled ? handleDeleteText : undefined} size={30} />
          )}
        </div>
      )
    }

    return (
      <InputText
        value={currentText}
        placeholder={placeholder}
        maxWidth={maxWidth}
        minWidth={minWidth}
        padding={padding}
        startContent={
          <FaSearch color='#325288' style={{ padding: '0 0 0 8px', margin: 'auto' }} className='icon-search' />
        }
        endContent={renderInputIcons()}
        isEnabled={isEnabled}
        onFocus={() => showTippy(true)}
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
    )
  }

  return (
    <Popover isVisible={isVisible} onHide={() => setIsVisible(false)} offset={offset}>
      <div className='input-search' style={{ margin }} ref={refInput}>
        {renderInput()}
      </div>
      <div className='items-container' style={{ width }}>
        {renderContent(filtered, getLabel, handleItemSelected)}
      </div>
    </Popover>
  )
}

InputSearch.defaultProps = {
  offset: [0, 4],
  timeout: 500,
  padding: '4px 8px',
  limit: 10,
  isEnabled: true,
  isLoading: false,
  isSelectOnly: false,
  getLabel: (item) => item?.label ?? '',
}

InputSearch.propTypes = {
  limit: PropTypes.number,
  text: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
    })
  ),
  placeholder: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  offset: PropTypes.arrayOf(PropTypes.any).isRequired,
  timeout: PropTypes.number.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSelectOnly: PropTypes.bool.isRequired,
  getLabel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRequestLoad: PropTypes.func,
}

export default InputSearch
