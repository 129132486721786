import { BsSearch } from '@react-icons/all-files/bs/BsSearch';
import classNames from 'classnames';
import { InputText } from 'mga-commons';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './SearchBar.css';

export const SearchBarVariants = Object.freeze({
    Small: 0,
    Full: 1
})

export const SearchBar = ({ variant }) => {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const query = searchParams.get('q');

    const [text, setText] = useState(query || '')

    const componentClass = classNames('search-bar', {
        small: variant === SearchBarVariants.Small,
        full: variant === SearchBarVariants.Full
    })

    useEffect(() => {

        if (!query) {
            setText('')
        }

    }, [query])

    const handleValidate = () => {

        if (text.length > 0) {

            navigate('/search?q=' + text)
        }
    }

    return (
        <div className="search-bar-container">
            <div className={componentClass} >
                <InputText
                    value={text }
                    onChange={value => setText(value)}
                    onValidate={handleValidate}
                    placeholder="Rechercher &quot;Médecin, spécialité, clinique, hôpital, ville, wilaya...&quot;"
                />
                <BsSearch onClick={handleValidate} />
            </div>
        </div>
    )
}

SearchBar.defaultProps = {
    variant: SearchBarVariants.Full
}

export default SearchBar
