import './InputNumber.css'

import { useEffect, useRef, useState } from 'react'

import Big from 'big.js'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const getConvertedToString = (value, nbDecimals, isNullAccepted) => {
  try {
    if (typeof value === 'undefined') return isNullAccepted ? '' : '0'
    let cleaned = value
    if (typeof value === 'string') {
      cleaned = cleaned.replace(/\s/g, '').replace(',', '.')
      if (cleaned === '') return isNullAccepted ? '' : '0'
      // Si dernier carac est un . ou , (saisie en cours)
      if (cleaned.indexOf('.') === cleaned.length - 1 || cleaned.indexOf('.') === cleaned.length - 1) return cleaned
    }
    const bigDec = new Big(cleaned).round(nbDecimals, Big.roundDown)
    const formatted = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: nbDecimals }).format(bigDec.toNumber())
    return formatted.replace('.', ',')
  } catch (error) {}
  return isNullAccepted ? '' : '0'
}

const getConvertedToNumber = (value, nbDecimals, isNullAccepted) => {
  try {
    if (value === '' || typeof value === 'undefined') return isNullAccepted ? null : 0
    const cleaned = value.replace(/\s/g, '').replace(',', '.')
    const bigDec = new Big(cleaned).round(nbDecimals, Big.roundDown)
    const res = bigDec.toNumber()
    return res
  } catch (error) {}
  return isNullAccepted ? null : 0
}

const NB_DEC = 3

const InputNumber = ({
  value,
  placeholder,
  maxWidth,
  padding,
  margin,
  nbDecimals,
  isEnabled,
  isNullAccepted,
  onChange,
  onFocus,
  onValidate,
}) => {
  const [currentValue, setCurrentValue] = useState('')

  const inputRef = useRef(null)

  const compClass = classNames('input-number', {
    disabled: !isEnabled,
  })

  const getNbDecimals = () => {
    return typeof nbDecimals === 'undefined' ? InputNumber.NB_DECIMALS : nbDecimals
  }

  useEffect(() => {
    // Update value
    setCurrentValue(getConvertedToString(value, getNbDecimals()))
  }, [value])

  const handleFocus = (event) => {
    // Select all
    event.target.select()

    // Fire event
    if (onFocus) onFocus(event)
  }

  const handleKeybord = (e) => {
    if (e.key === 'Enter') {
      if (onValidate) {
        onValidate()

        inputRef.current?.blur()
      }
    }
  }

  const handleChange = (val) => {
    const regEx = new RegExp(`^((\\d){1,3}(\\s){0,1})*(.|,){0,1}((\\d){0,${getNbDecimals()}})$`, 'i')

    if (!regEx.test(val)) return

    setCurrentValue(getConvertedToString(val, getNbDecimals(), isNullAccepted))

    onChange(getConvertedToNumber(val, getNbDecimals(), isNullAccepted))
  }

  return (
    <input
      type='text'
      style={{ maxWidth, padding, margin }}
      readOnly={!isEnabled}
      className={compClass}
      name='InputNumber'
      autoFocus
      placeholder={placeholder}
      value={currentValue || ''}
      onChange={(event) => handleChange(event.target.value)}
      onFocus={handleFocus}
      onKeyUp={handleKeybord}
      ref={inputRef}
    />
  )
}

InputNumber.NB_DECIMALS = NB_DEC

InputNumber.displayName = 'InputNumber'

InputNumber.defaultProps = {
  isEnabled: true,
  isNullAccepted: false,
  padding: '0.4rem 0.8rem',
}

InputNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  maxWidth: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  nbDecimals: PropTypes.number,
  isNullAccepted: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onValidate: PropTypes.func,
}

export default InputNumber
