import './InputCheckbox.css'

import { FaRegCheckSquare } from '@react-icons/all-files/fa/FaRegCheckSquare'
import { FaRegSquare } from '@react-icons/all-files/fa/FaRegSquare'
import PropTypes from 'prop-types'

const InputCheckbox = ({ isChecked, label, margin, onChange }) => {
  return (
    <label className='input-checkbox' style={{ margin }}>
      {isChecked ? <FaRegCheckSquare size='22' /> : <FaRegSquare size='22' />}
      <input type='checkbox' checked={isChecked} onChange={() => onChange(!isChecked)} />
      <span>{label}</span>
    </label>
  )
}

InputCheckbox.propTypes = {
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  margin: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default InputCheckbox
