import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import createRootReducer from './reducers'
import thunk from 'redux-thunk'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')

export const history = createBrowserHistory({ basename: baseUrl })

export default function configureStore(preloadedState) {
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        compose(
            applyMiddleware(
                thunk
            ),
        ),
    )

    return store
}