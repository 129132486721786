import './BtnToggle.css'

import PropTypes from 'prop-types'
import classNames from 'classnames'

export const BtnToggleVariants = Object.freeze({
  Grey: 0,
  Blue: 1,
})

const renderItem = (item, index, count, selected, variant, padding, fontSize, fontWeight, getLabel, onChange) => {
  let componentClass = classNames('btn-toggle-item', {
    first: index === 0,
    last: index === count - 1,
    selected: selected === item,
    grey: variant === BtnToggleVariants.Grey,
    blue: variant === BtnToggleVariants.Blue,
  })

  return (
    <div
      key={index}
      style={{ padding, fontSize, fontWeight }}
      className={componentClass}
      onClick={() => onChange(item)}
    >
      <span>{getLabel(item)}</span>
    </div>
  )
}

const BtnToggle = ({
  items,
  value,
  fontSize,
  fontWeight,
  variant,
  margin,
  padding,
  maxWidth,
  minWidth,
  getLabel,
  onChange,
}) => {
  let compClass = classNames('btn-toggle', {})

  const handleChange = (val) => {
    onChange(val)
  }

  const count = items.length

  return (
    <div className={compClass} style={{ margin, maxWidth, minWidth }}>
      {items.map((item, index) =>
        renderItem(item, index, count, value, variant, padding, fontSize, fontWeight, getLabel, handleChange)
      )}
    </div>
  )
}

BtnToggle.defaultProps = {
  variant: BtnToggleVariants.Grey,
  fontSize: '0.8rem',
  fontWeight: 'bold',
  padding: '2px 20px',
  getLabel: (item) => item?.label ?? '',
}

BtnToggle.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.any,
  fontSize: PropTypes.string.isRequired,
  fontWeight: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(BtnToggleVariants)),
  margin: PropTypes.string,
  padding: PropTypes.string.isRequired,
  getLabel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default BtnToggle
