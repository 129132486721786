import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './Card.module.css'

export const CardVariants = Object.freeze({
  Simple: 1,
  Flat: 2,
  Raised: 2,
})

const Card = ({ children, variant, padding, margin, minWidth, maxWidth, minHeight, maxHeight, className }) => {
  var cx = classNames.bind(styles)

  const compClass = cx('card', {
    simple: variant === CardVariants.Simple,
    flat: variant === CardVariants.Flat,
    raised: variant === CardVariants.Raised,
  })

  return (
    <div
      className={compClass + (className ? ` ${className}` : '')}
      style={{ padding, margin, minWidth, maxWidth, minHeight, maxHeight }}
    >
      {children}
    </div>
  )
}

Card.defaultProps = {
  padding: '10px 20px',
  variant: CardVariants.Simple,
}

Card.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(CardVariants)),
  padding: PropTypes.string,
  margin: PropTypes.string,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
}

export default Card
