

export const EnumTypeContact = {
    Telephone: {
        value: 1,
        label: "Tel"
    },
    Mobile: {
        value: 2,
        label: "Mobile"
    },
    Fixe: {
        value: 3,
        label: "Fixe"
    },
    Email: {
        value: 4,
        label: "Email"
    },
    SiteWeb: {
        value: 5,
        label: "Site Web"
    },
    Facebook: {
        value: 6,
        label: "Facebook"
    },
    Twitter: {
        value: 7,
        label: "Twitter"
    },
    Twitter: {
        value: 99,
        label: "autre"
    }
}


export const EnumTypeFiche = {
    Medecin: {
        Value: 1,
        Label: "Médecin"
    },
    Etablissement: {
        Value: 2,
        Label: "Établissement"
    },
    Service: {
        Value: 3,
        Label: "Service"
    }
}


export const EnumStatutFichier = {
    EnAttente: {
        Value: 1,
        Label: "En attente"
    },
    Validee: {
        Value: 2,
        Label: "Validée"
    },
    Refusee: {
        Value: 3,
        Label: "Refusée"
    }
}


export const EnumTypeFichier = {
    Profil: {
        Value: 1,
        Label: "Photo de profil"
    },
    Cabinet: {
        Value: 2,
        Label: "Photos de votre cabinet"
    },
    Etablissement: {
        Value: 3,
        Label: "Photos de votre établissement"
    },
    Service: {
        Value: 4,
        Label: "Photos de votre service"
    },
    Autres: {
        Value: 5,
        Label: "Autres photos"
    }
}

export const EnumStatutFiche = {
    Desactivee: {
        Value: 0,
        Label: "Désactivée"
    },
    EnAttente: {
        Value: 1,
        Label: "En attente"
    },
    Refusee: {
        Value: 2,
        Label: "Refusée"
    },
    Validee: {
        Value: 3,
        Label: "Validée"
    }
}

export const EnumProfil = {
    Patient: {
        value: 1,
        label: "Patient"
    },
    Admin: {
        value: 999,
        label: "Admin"
    },
    Pro: {
        value: 100,
        label: "Professionnel"
    }
}

export const EnumGenre = {
    Homme: {
        value: 1,
        label: "Homme"
    },
    Femme: {
        value: 2,
        label: "Femme"
    }
}

export const EnumSituation = {
    Celibataire: {
        value: 1,
        label: "Célibataire"
    },
    Marie: {
        value: 2,
        label: "Marié(e)"
    },
    VeufOuDivorce: {
        value: 3,
        label: "Autre"
    }
}


export const EnumEmailStatus = {
    Success: {
        value: 1,
        label: "Success"
    },
    Error: {
        value: 2,
        label: "Error"
    }
}
