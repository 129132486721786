import React from 'react';
import { FaAngleRight } from '@react-icons/all-files/fa/FaAngleRight'
import { FaChevronDown } from '@react-icons/all-files/fa/FaChevronDown'
import { Link, useLocation } from "react-router-dom";
import SearchBar from '../SearchBar';
import './HeaderBottom.css';


export const HeaderBottom = ({ }) => {

    const location = useLocation();

    const isHome = location.pathname === '/'

    return (
        <div className="header-bottom">
            <Link to='/'>
                <img loading="lazy" className="logo" width="100" height="40" src="/images/logo.png" alt="logo algerie-docto algerie docto" />
            </Link>
            <ul id="menu-deroulant">
                <li>
                    <Link to="/" className="menu-item">
                        Accueil
                    </Link>
                </li>
                <li>
                    <Link to="/wilaya/16/alger" className="menu-item">
                        Spécialités
                        <FaChevronDown />
                    </Link>
                    <ul className="menu-deroulant-liste">
                        <li>
                            <a href="/medecin/type/4/cardiologie" className="menu-item">
                                <FaAngleRight />
                                Cardiologie
                            </a>
                        </li>
                        <li>
                            <a href="/medecin/type/12/dermatologie" className="menu-item">
                                <FaAngleRight />
                                Dermatologie
                            </a>
                        </li>
                        <li>
                            <a href="/medecin/type/23/medecine-generale" className="menu-item">
                                <FaAngleRight />
                                Médecine générale
                            </a>
                        </li>
                        <li>
                            <a href="/medecin/type/30/neurologie" className="menu-item">
                                <FaAngleRight />
                                Neurologie
                            </a>
                        </li>
                        <li>
                            <a href="/medecin/type/33/orl" className="menu-item">
                                <FaAngleRight />
                                ORL
                            </a>
                        </li>
                        <li>
                            <a href="/medecin/type/35/pediatrie" className="menu-item">
                                <FaAngleRight />
                                Pédiatrie
                            </a>
                        </li>
                        <li>
                            <a href="/medecin/type/43/urologie" className="menu-item">
                                <FaAngleRight />
                                Urologie
                            </a>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="/wilaya/16/alger" className="menu-item">
                        Aide
                        <FaChevronDown />
                    </Link>
                    <ul className="menu-deroulant-liste">
                        <li>
                            <a href="/contact" className="menu-item">
                                <FaAngleRight />
                                Nous écrire
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <div className="search-container">
                {!isHome && <SearchBar />}
            </div>
        </div>
    )
}

export default HeaderBottom
