import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'anonymousRecoverySlice'

export const anonymousRecoveryGetItem = createAsyncThunk(sliceName + "/anonymousRecoveryGetItem", async (data, thunkAPI) => {
    try {

        return await axios.post(`recovery/RecoveryGetItem`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const anonymousRecoverySubmitItem = createAsyncThunk(sliceName + "/anonymousRecoverySubmitItem", async (data, thunkAPI) => {
    try {

        return await axios.post(`recovery/RecoverySubmitItem`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

const initialState = {
    isLoading: null,
    isSuccess: null,

    error: null,
};

export const anonymousRecoverySlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        initRecovery(state) {
            state.isLoading = null;
            state.isSuccess = null;
            state.error = null;
        },
    },
    extraReducers: (builder) => {

        // anonymousRecoveryGetItem
        builder.addCase(anonymousRecoveryGetItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(anonymousRecoveryGetItem.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(anonymousRecoveryGetItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
        });

        // anonymousRecoverySubmitItem
        builder.addCase(anonymousRecoverySubmitItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(anonymousRecoverySubmitItem.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(anonymousRecoverySubmitItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
        });

    }
});

// Actions
export const { initRecovery } = anonymousRecoverySlice.actions

export const selectIsLoading = (state) => state.anonymousRecoveryData.isLoading;
export const selectIsSuccess = (state) => state.anonymousRecoveryData.isSuccess;
export const selectError = (state) => state.anonymousRecoveryData.error;

export default anonymousRecoverySlice.reducer;