
export const GetHost = function () {
    var protocol = window.location.protocol
    var slashes = protocol.concat("//")
    return slashes.concat(window.location.hostname) + (window.location.port ? ':' + window.location.port : '')
}

export const GetHostApi = function () {
    return GetHost() + '/api'
}


