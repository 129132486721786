import './LinkText.css'

import Tooltip from '../Tooltip/Tooltip'
import classNames from 'classnames'

export const LinkTextVariants = Object.freeze({
  Disabled: 0,
  Fatal: 1,
  Info: 2,
})

const LinkText = ({ children, tooltip, variant, onClick }) => {
  const componentClass = classNames('link-text', {
    disabled: !variant || variant === LinkTextVariants.Disabled,
    fatal: variant === LinkTextVariants.Fatal,
    info: variant === LinkTextVariants.Info,
  })

  const handleClick = (e) => {
    if (variant !== LinkTextVariants.Disabled) {
      onClick(e)
    }
  }

  if (!tooltip) {
    return (
      <span className={componentClass} onClick={handleClick}>
        {children}
      </span>
    )
  }

  return (
    <Tooltip value={tooltip}>
      <span className={componentClass} onClick={handleClick}>
        {children}
      </span>
    </Tooltip>
  )
}

export default LinkText
