import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'patientRdvSlice'

export const patientRdvGetItems = createAsyncThunk(sliceName + "/patientRdvGetItems", async (data, thunkAPI) => {
    try {

        return await axios.get(`patients-rdvs/RdvGetItems`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }
        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const patientRdvDeleteItem = createAsyncThunk(sliceName + "/patientRdvDeleteItem", async (data, thunkAPI) => {
    try {

        return await axios.delete(`patients-rdvs/RdvDeleteItem`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const patientRdvDispoGetItems = createAsyncThunk(sliceName + "/patientRdvDispoGetItems", async (data, thunkAPI) => {
    try {
        return await axios.get(`patients-rdvs/RdvHoraireDispoGetItems`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const patientRdvBookItem = createAsyncThunk(sliceName + "/patientRdvBookItem", async (data, thunkAPI) => {
    try {
        return await axios.post(`patients-rdvs/RdvBookItem`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


const initialState = {
    isLoading: false,
    rdvItems: null,

    isLoadingRdvDispoItems: false,
    rdvDispoItems: null,
};

export const patientRdvSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        initDispoItems(state) {
            state.rdvDispoItems = null;
        },
    },
    extraReducers: (builder) => {

        // patientRdvGetItems
        builder.addCase(patientRdvGetItems.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(patientRdvGetItems.rejected, (state, action) => {
            state.isLoading = false;
            state.rdvItems = [];
        });
        builder.addCase(patientRdvGetItems.fulfilled, (state, action) => {
            state.isLoading = false;
            state.rdvItems = action.payload.data;
        });

        // patientRdvDeleteItem
        builder.addCase(patientRdvDeleteItem.fulfilled, (state, action) => {
            state.rdvItems = state.rdvItems?.filter(x => x.IdRdv !== action.payload.data);
        });


        // patientRdvDispoGetItems
        builder.addCase(patientRdvDispoGetItems.pending, (state, action) => {
            state.isLoadingRdvDispoItems = true;
        });
        builder.addCase(patientRdvDispoGetItems.rejected, (state, action) => {
            state.isLoadingRdvDispoItems = false;
        });
        builder.addCase(patientRdvDispoGetItems.fulfilled, (state, action) => {
            state.isLoadingRdvDispoItems = false;
            state.rdvDispoItems = action.payload.data;
        });

        // patientRdvBookItem
        builder.addCase(patientRdvBookItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(patientRdvBookItem.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(patientRdvBookItem.fulfilled, (state, action) => {
            state.isLoading = false;
        });

    }
});

// Actions
export const { initDispoItems } = patientRdvSlice.actions

export const selectIsLoading = (state) => state.patientRdvData.isLoading;
export const selectRdvItems = (state) => state.patientRdvData.rdvItems;
export const selectRdv = (state) => state.patientRdvData.rdv;


export const selectIsLoadingRdvDispoItems = (state) => state.patientRdvData.isLoadingRdvDispoItems;
export const selectRdvDispoItems = (state) => state.patientRdvData.rdvDispoItems;

export default patientRdvSlice.reducer;