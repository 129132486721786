
import { BiLogOut } from '@react-icons/all-files/bi/BiLogOut';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { selectUserProfile } from '../../../slices/anonymous/anonymousAuthSlice';
import './MenuManager.css';

const MenuManager = () => {

    const userProfile = useSelector(selectUserProfile)

    const parsed = dayjs(userProfile?.DerniereConnexion)

    return (
        <div className="menu-manager">
            <Link to="/" className="image-container">
                <img loading="lazy" className="logo" src="/images/logo.png" alt="logo algerie-docto docto pro" />
            </Link>
            <div className="acces">
                <span>Dernier accès</span>
                <span>{parsed.format('DD/MM/YYYY HH:mm')}</span>
            </div>
            <Link to="/connexion" className="disconnet">
                <BiLogOut />
                Se déconnecter
            </Link>
            <NavLink to="/manager/pro/accueil" className="menu-item">
                Accueil
            </NavLink>
            <NavLink to={`/manager/pro/patients`} className="menu-item">
                Patients
            </NavLink>
            <NavLink to={`/manager/pro/rendez-vous`} className="menu-item">
                Rendez-vous
            </NavLink>
            <NavLink to={`/manager/pro/planning`} className="menu-item">
                Planning
            </NavLink>
            <NavLink to={`/manager/pro/fiche-edit/` + userProfile?.IdFiche} className="menu-item">
                Fiche en ligne
            </NavLink >
            <NavLink to="/manager/pro/parametres" className="menu-item">
                Paramètres
            </NavLink>
            <br />
            <br />
        </div>
    )
}

export default MenuManager