import './InputTextArea.css'

import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'

const InputTextArea = ({
  value,
  placeholder,
  maxLength,
  width,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  padding,
  margin,
  rows,
  isEnabled,
  isAutoComplete,
  onChange,
  onValidate,
}) => {
  const [currentValue, setCurrentValue] = useState('')

  useEffect(() => {
    // Update value
    setCurrentValue(value)
  }, [value])

  const compClass = classNames('input-text-area', {
    disabled: !isEnabled,
  })

  const handleChange = (event) => {
    setCurrentValue(event.target.value)

    onChange(event.target.value)
  }

  const handleKeybord = (e) => {
    if (e.key === 'Enter') {
      if (onValidate) {
        onValidate()
      }
    }
  }

  var props = {}

  if (!isAutoComplete) {
    props = { autoComplete: 'off' }
  }

  return (
    <textarea
      {...props}
      rows={rows}
      value={currentValue || ''}
      style={{ margin, padding, width, maxWidth, minWidth, height, maxHeight, minHeight }}
      disabled={!isEnabled ? 'disabled' : ''}
      maxLength={maxLength}
      className={compClass}
      placeholder={placeholder}
      onFocus={(e) => e.target.select()}
      onChange={handleChange}
      onKeyUp={handleKeybord}
    />
  )
}

InputTextArea.defaultProps = {
  rows: '5',
  maxLength: 1000,
  padding: '8px 10px',
  isEnabled: true,
  isAutoComplete: false,
}

InputTextArea.propTypes = {
  value: PropTypes.string,
  rows: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  height: PropTypes.string,
  maxHeight: PropTypes.string,
  minHeight: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  isAutoComplete: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
}

export default InputTextArea
