import PropTypes from 'prop-types'
import { VscTriangleDown } from '@react-icons/all-files/vsc/VscTriangleDown'
import { VscTriangleLeft } from '@react-icons/all-files/vsc/VscTriangleLeft'
import classNames from 'classnames/bind'
import styles from './Collapsible.module.css'
import { useEffect } from 'react'
import { useState } from 'react'

const Collapsible = ({ children, titleContent, margin, maxWidth, minWidth, headerStyle, isExpanded, onExpand }) => {
  var cx = classNames.bind(styles)

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(isExpanded)
  }, [isExpanded])

  const compClass = cx('collapsible', {
    expanded: isVisible,
  })

  const headerClass = cx('header')
  const titleClass = cx('title')

  const handleExpand = (paramVal) => {
    setIsVisible(paramVal)
    onExpand && onExpand(paramVal)
  }

  return (
    <div className={compClass} style={{ margin, maxWidth, minWidth }}>
      <div className={headerClass} style={{ ...headerStyle }} onClick={() => handleExpand(!isVisible)}>
        <div className={titleClass}>{titleContent}</div>
        {isVisible ? <VscTriangleDown /> : <VscTriangleLeft />}
      </div>
      {isVisible ? children : null}
    </div>
  )
}

Collapsible.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default Collapsible
