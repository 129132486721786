import './Popover.css'

import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react/headless'

const Popover = ({ children, placement, isVisible, offset, onHide }) => {
  const renderContent = () => {
    return <div className='popover'>{children[1]}</div>
  }

  // Intercative a true : permettre le hover css sur les elements
  return (
    <Tippy
      render={(attrs) => renderContent()}
      visible={isVisible}
      onClickOutside={onHide}
      placement={placement}
      offset={offset}
      interactive={true}>
      {children[0]}
    </Tippy>
  )
}

Popover.defaultProps = {
  placement: 'bottom-start',
}

Popover.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node.isRequired),
  placement: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default Popover
