import { useEffect } from "react";
import { useLayoutEffect, useState } from "react";
import { Router } from "react-router";
import { SendPageChange } from "./utils/AnalyticsUtils";

const CustomRouter = ({ history, ...props }) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    useEffect(() => {

        // Google Analytics Track pages
        SendPageChange(window.location.pathname + window.location.search)

    }, [history.location]);


    return (
        <Router
            {...props}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    );
};

export default CustomRouter