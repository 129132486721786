import './CollapsibleSearch.css'

import Collapsible from '../Collapsible'
import { FaSearch } from '@react-icons/all-files/fa/FaSearch'
import InputText from '../InputText'
import PropTypes from 'prop-types'
import { useRef } from 'react'

const CollapsibleSearch = ({ title, defaultValue, children, onChange, onFocus }) => {
  const childRef = useRef()

  const handleFocus = () => {
    childRef.current.setIsVisible(true)
  }

  return (
    <div>
      <Collapsible defaultValue={defaultValue} title={title} ref={childRef}>
        <InputText
          startContent={<FaSearch />}
          placeholder='Rechercher des articles...'
          onChange={onChange}
          onFocus={handleFocus}
        />
        {children}
      </Collapsible>
    </div>
  )
}

//CollapsibleSearch.propTypes = {
//    title: PropTypes.string.isRequired,
//    children: PropTypes.node.isRequired,
//    onChange: PropTypes.func.isRequired
//}

export default CollapsibleSearch
