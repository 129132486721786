
import axios from '../utils/Axios'

const PROF_KEY = 'gjAdccz89aUaLZ79j2'

Object.cast = function cast(rawObj, constructor) {
    var obj = new constructor();
    for (var i in rawObj)
        obj[i] = rawObj[i];
    return obj;
}

export const GetProfileFromStorage = function () {

    //if (typeof (Storage) !== "undefined") {
    if (typeof (localStorage) !== "undefined") {

        let storageTmp = localStorage.getItem(PROF_KEY)

        if ((typeof storageTmp === "undefined") || (storageTmp == null))
            return null

        // Parse json
        let userProfile = JSON.parse(storageTmp)

        if ((typeof userProfile === "undefined") || (userProfile == null) || (userProfile.Token == null))
            return null

        // Set token
        Object.assign(axios.defaults, { headers: { token: userProfile.Token } })

        return userProfile
    }
    return null
}

export const SaveProfileToStorage = function (paramProfile) {

    // Set token
    Object.assign(axios.defaults, { headers: { token: paramProfile.Token } })

    localStorage.setItem(PROF_KEY, JSON.stringify(paramProfile))
}

export const RemoveProfileFromStorage = function () {

    // Init token
    Object.assign(axios.defaults, { headers: { token: null } })

    localStorage.removeItem(PROF_KEY)
}


