// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rUtWBR2XI0ZgpczG0Uhf {\r\n  background-color: white;\r\n  border: 1px solid #eeeeee;\r\n  border-radius: 4px;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.rUtWBR2XI0ZgpczG0Uhf.FaxVz15lkdopU7okgRv2 {\r\n  box-shadow: 0 2px 2px 0 rgb(67 95 113 / 10%);\r\n}\r\n\r\n.rUtWBR2XI0ZgpczG0Uhf.OiFzxQo1mIlmD_kODjpV {\r\n  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;\r\n}\r\n\r\n.rUtWBR2XI0ZgpczG0Uhf._uRoV84fZAFoCuNltsg4 {\r\n  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/Card/Card.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,wFAAwF;AAC1F;;AAEA;EACE,yFAAyF;AAC3F","sourcesContent":[".card {\r\n  background-color: white;\r\n  border: 1px solid #eeeeee;\r\n  border-radius: 4px;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.card.simple {\r\n  box-shadow: 0 2px 2px 0 rgb(67 95 113 / 10%);\r\n}\r\n\r\n.card.flat {\r\n  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;\r\n}\r\n\r\n.card.raised {\r\n  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "rUtWBR2XI0ZgpczG0Uhf",
	"simple": "FaxVz15lkdopU7okgRv2",
	"flat": "OiFzxQo1mIlmD_kODjpV",
	"raised": "_uRoV84fZAFoCuNltsg4"
};
export default ___CSS_LOADER_EXPORT___;
