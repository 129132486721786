import './TableCell.css'

import PropTypes from 'prop-types'
import classNames from 'classnames'

export const TableCellVariants = Object.freeze({
  Normal: 0,
  Info: 1,
  Warning: 2,
  Error: 3,
  Ok: 4,
  Other: 5,
})

export const TableCellAlignVariants = Object.freeze({
  Right: 1,
  Center: 2,
  SpaceBetween: 3,
})

const TableCell = ({ children, variant, align, title, isBold, isCapitalize, padding, onClick }) => {
  const compClass = classNames('table-cell', {
    normal: variant === TableCellVariants.Normal,
    info: variant === TableCellVariants.Info,
    warning: variant === TableCellVariants.Warning,
    error: variant === TableCellVariants.Error,
    ok: variant === TableCellVariants.Ok,
    other: variant === TableCellVariants.Other,
    center: align === TableCellAlignVariants.Center,
    right: align === TableCellAlignVariants.Right,
    'space-between': align === TableCellAlignVariants.SpaceBetween,
    bold: isBold ?? false,
    capitalize: isCapitalize,
  })

  let props = {}

  if (title) {
    props = {
      title,
    }
  }

  return (
    <div className={compClass} style={{ padding }} {...props} onClick={onClick}>
      {children}
    </div>
  )
}

TableCell.defaultProps = {
  padding: '0 10px',
}

export default TableCell
