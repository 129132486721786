import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { cloneElement } from 'react'
import styles from './Btn.module.css'

export const BtnVariants = Object.freeze({
  Primary: 1,
  PrimaryOutline: 2,
  Secondary: 3,
  SecondaryOutline: 4,
  Fatal: 5,
})

const Btn = ({ children, variant, margin, padding, minWidth, maxWidth, size, isEnabled, onClick }) => {
  var cx = classNames.bind(styles)

  const compClass = cx('btn', {
    disabled: !isEnabled,
    primary: variant === BtnVariants.Primary,
    'primary-outline': variant === BtnVariants.PrimaryOutline,
    secondary: variant === BtnVariants.Secondary,
    'secondary-outline': variant === BtnVariants.SecondaryOutline,
    fatal: variant === BtnVariants.Fatal,
  })

  const handleClick = (e) => {
    if (isEnabled) {
      onClick(e)
    }
  }

  if (variant === BtnVariants.Empty) {
    return cloneElement(children, { onClick: handleClick })
  }

  return (
    <div className={compClass} style={{ padding, margin, maxWidth, minWidth, fontSize: size }} onClick={handleClick}>
      {children}
    </div>
  )
}

Btn.defaultProps = {
  isEnabled: true,
  variant: BtnVariants.Primary,
  padding: '0.4rem 0.8rem',
}

Btn.propTypes = {
  size: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(Object.values(BtnVariants)),
  isEnabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Btn
