import './LoaderPanel.css'

import Loader from '../Loader/Loader'
import PanelCenter from '../PanelCenter'

const LoaderPanel = ({ margin, padding }) => {
  return (
    <div className='loader-panel' style={{ margin, padding }}>
      <PanelCenter>
        <h3>Chargement en cours...</h3>
        <br />
        <Loader color='#325288' />
        <br />
      </PanelCenter>
    </div>
  )
}

export default LoaderPanel
