import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './InputText.module.css'

const InputText = ({
  value,
  placeholder,
  fontSize,
  maxLength,
  minWidth,
  maxWidth,
  type,
  padding,
  margin,
  startContent,
  endContent,
  isCentered,
  isEnabled,
  isAutoComplete,
  onChange,
  onFocus,
  onBlur,
  onValidate,
  onClick,
}) => {
  var cx = classNames.bind(styles)

  const compClass = cx('input-text', {
    disabled: !isEnabled,
    centered: isCentered,
  })

  const handleFocus = (event) => {
    // Select all
    event.target.select()
    // Focus
    if (onFocus && isEnabled) onFocus(event)
  }

  const handleKeybord = (e) => {
    if (e.key === 'Enter') {
      if (onValidate && isEnabled) {
        onValidate()
      }
    }
  }

  var props = {}

  if (!isAutoComplete) {
    props = { autoComplete: 'off' }
  }

  return (
    <div className={compClass} style={{ minWidth, maxWidth, margin }}>
      <span onClick={onClick ? onClick : undefined}>{startContent}</span>
      <input
        type={type ? type : 'text'}
        style={{ padding, fontSize }}
        disabled={!isEnabled ? 'disabled' : ''}
        maxLength={maxLength}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        value={value || ''}
        onFocus={handleFocus}
        onKeyUp={handleKeybord}
        onBlur={onBlur ? onBlur : undefined}
        onClick={onClick ? onClick : undefined}
        {...props}
      />
      <span onClick={onClick ? onClick : undefined}>{endContent}</span>
    </div>
  )
}

InputText.defaultProps = {
  maxLength: 100,
  padding: '4px 8px',
  type: 'text',
  isCentered: false,
  isEnabled: true,
  isAutoComplete: false,
}

InputText.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  fontSize: PropTypes.string,
  maxLength: PropTypes.number.isRequired,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  type: PropTypes.string.isRequired,
  padding: PropTypes.string.isRequired,
  margin: PropTypes.string,
  startContent: PropTypes.any,
  endContent: PropTypes.any,
  isCentered: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isAutoComplete: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onValidate: PropTypes.func,
  onClick: PropTypes.func,
}

export default InputText
