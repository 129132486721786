import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'proPlanningSlice'


// ########### PLANNING DISPO

export const proPlanningDispoGetItems = createAsyncThunk(sliceName + "/proPlanningDispoGetItems", async (data, thunkAPI) => {
    try {

        return await axios.get(`manager-plannings/PlanningDispoGetItems`, {
            params: data
        });

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const proPlanningDispoAddItems = createAsyncThunk(sliceName + "/proPlanningDispoAddItems", async (data, thunkAPI) => {
    try {

        return await axios.post(`manager-plannings/PlanningDispoAddItems`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const proPlanningDispoDeleteItem = createAsyncThunk(sliceName + "/proPlanningDispoDeleteItem", async (data, thunkAPI) => {
    try {

        await axios.delete(`manager-plannings/PlanningDispoDeleteItem`, {
            params: data
        });

        return thunkAPI.fulfillWithValue(data.idPlanning);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


// ########### PLANNING INDISPO


export const proPlanningIndispoGetItems = createAsyncThunk(sliceName + "/proPlanningIndispoGetItems", async (data, thunkAPI) => {
    try {

        return await axios.get(`manager-plannings/PlanningIndispoGetItems`, {
            params: data
        });

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const proPlanningIndispoAddItem = createAsyncThunk(sliceName + "/proPlanningIndispoAddItem", async (data, thunkAPI) => {
    try {

        return await axios.post(`manager-plannings/PlanningIndispoAddItem`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const proPlanningIndispoDeleteItem = createAsyncThunk(sliceName + "/proPlanningIndispoDeleteItem", async (data, thunkAPI) => {
    try {

        return await axios.delete(`manager-plannings/PlanningIndispoDeleteItem`, {
            params: data
        });

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


const initialState = {
    isLoadingIndispo: false,
    isLoadingDispo: false,

    isEnabled: true,

    dispoItems : [],
    indispoItems: [],
};

export const proPlanningSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        // proPlanningDispoGetItems
        builder.addCase(proPlanningDispoGetItems.pending, (state, action) => {
            state.isLoadingDispo = true;
        });
        builder.addCase(proPlanningDispoGetItems.rejected, (state, action) => {
            state.isLoadingDispo = false;
        });
        builder.addCase(proPlanningDispoGetItems.fulfilled, (state, action) => {
            state.isLoadingDispo = false;
            state.dispoItems = action.payload.data
        });

        // proPlanningDispoAddItems
        builder.addCase(proPlanningDispoAddItems.pending, (state, action) => {
            state.isEnabled = false;
        });
        builder.addCase(proPlanningDispoAddItems.rejected, (state, action) => {
            state.isEnabled = true;
        });
        builder.addCase(proPlanningDispoAddItems.fulfilled, (state, action) => {
            state.isEnabled = true;
            state.dispoItems = action.payload.data
        });

        // proPlanningDispoDeleteItem
        builder.addCase(proPlanningDispoDeleteItem.pending, (state, action) => {
            state.isEnabled = false;
        });
        builder.addCase(proPlanningDispoDeleteItem.rejected, (state, action) => {
            state.isEnabled = true;
        });
        builder.addCase(proPlanningDispoDeleteItem.fulfilled, (state, action) => {
            state.isEnabled = true;
            state.dispoItems.splice(state.dispoItems.findIndex((x) => x.IdPlanningDispo === action.payload), 1);
        });

        // ############## INDISPO


        // proPlanningIndispoGetItems
        builder.addCase(proPlanningIndispoGetItems.pending, (state, action) => {
            state.isLoadingIndispo = true;
        });
        builder.addCase(proPlanningIndispoGetItems.rejected, (state, action) => {
            state.isLoadingIndispo = false;
        });
        builder.addCase(proPlanningIndispoGetItems.fulfilled, (state, action) => {
            state.isLoadingIndispo = false;
            state.indispoItems = action.payload.data
        });

        // proPlanningIndispoAddItem
        builder.addCase(proPlanningIndispoAddItem.pending, (state, action) => {
            state.isEnabled = false;
        });
        builder.addCase(proPlanningIndispoAddItem.rejected, (state, action) => {
            state.isEnabled = true;
        });
        builder.addCase(proPlanningIndispoAddItem.fulfilled, (state, action) => {
            state.isEnabled = true;
            state.indispoItems.push(action.payload.data)
        });

        // proPlanningIndispoDeleteItem
        builder.addCase(proPlanningIndispoDeleteItem.pending, (state, action) => {
            state.isEnabled = false;
        });
        builder.addCase(proPlanningIndispoDeleteItem.rejected, (state, action) => {
            state.isEnabled = true;
        });
        builder.addCase(proPlanningIndispoDeleteItem.fulfilled, (state, action) => {
            state.isEnabled = true;
            state.indispoItems.splice(state.indispoItems.findIndex((x) => x.IdPlanning === action.payload), 1);
        });


    }
});

export const selectIsLoadingDispo = (state) => state.proPlanningData.isLoadingDispo;
export const selectIsLoadingIndispo = (state) => state.proPlanningData.isLoadingIndispo;

export const selectDispoItems = (state) => state.proPlanningData.dispoItems;
export const selectIndispoItems = (state) => state.proPlanningData.indispoItems;
export const selectIsEnabled = (state) => state.proPlanningData.isEnabled;


export default proPlanningSlice.reducer;