
import axios from 'axios'
import { GetHostApi } from './NetworkUtils'

const instance = axios.create({
    baseURL: GetHostApi(),
    timeout: 60000,
    //withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})


instance.interceptors.response.use(null, function (err) {

    if (err.response.status === 401) {

        // Redirect to login if 'HttpStatusCode.Unauthorized'
        window.location.replace("/connexion");

    }
    return Promise.reject(err);
});

export default instance