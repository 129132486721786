import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'proFichierSlice'

export const proFichierGetItems = createAsyncThunk(sliceName + "/proFichierGetItems", async (data, thunkAPI) => {
    try {

        return await axios.get(`manager-fichiers/FichierGetItems`);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const proFichierAddItem = createAsyncThunk(sliceName + "/proFichierAddItem", async (data, thunkAPI) => {
    try {

        var formData = new FormData();

        formData.append("Mime", data.Mime);
        formData.append("Nom", data.Nom);
        formData.append("Fichier", data.Fichier);
        formData.append("TypeFichier", data.TypeFichier);

        return await axios.post(`manager-fichiers/FichierAddItem`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const proFichierDeleteItem = createAsyncThunk(sliceName + '/proFichierDeleteItem', async (data, thunkAPI) => {
    try {

        await axios.delete(`manager-fichiers/FichierDeleteItem`, {
            params: data
        });

        return thunkAPI.fulfillWithValue(data.uniqueId)

    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})


const initialState = {
    isLoading: false,
    fichierItems : null
};

export const proFichierSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: { },
    extraReducers: (builder) => {

        // proFichierGetItems
        builder.addCase(proFichierGetItems.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(proFichierGetItems.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(proFichierGetItems.fulfilled, (state, action) => {
            state.isLoading = false;
            state.fichierItems = action.payload.data;
        });

        // proFichierAddItem
        builder.addCase(proFichierAddItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(proFichierAddItem.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(proFichierAddItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.fichierItems = state.fichierItems.concat(action.payload.data);
        });

        // proFichierDeleteItem
        builder.addCase(proFichierDeleteItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(proFichierDeleteItem.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(proFichierDeleteItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.fichierItems = state.fichierItems.filter(item => item.UniqueId !== action.payload)
        });
        
    }
});


export const selectFichierItems = (state) => state.proFichierData.fichierItems;
export const selectIsLoading = (state) => state.proFichierData.isLoading;

export default proFichierSlice.reducer;