import { useState } from 'react';
import { GiHamburgerMenu } from '@react-icons/all-files/gi/GiHamburgerMenu'
import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight'
import { Link } from "react-router-dom";
import './HeaderMobile.css';


export const HeaderMobile = ({ }) => {

    const [isMenuHidden, setIsMenuHidden] = useState(false)

    const handleToggle = () => {
        setIsMenuHidden(!isMenuHidden)
    }

    return (
        <div className="header-mobile">
            <div className="menu">
                <Link to='/'>
                    <img loading="lazy" className="logo" width="100" height="40" src="/images/logo.png" alt="logo algerie-docto algerie docto" />
                </Link>
                <GiHamburgerMenu onClick={handleToggle} />
            </div>
            {isMenuHidden &&
                (
                    <div className="content">
                        <Link className="item" to="/" onClick={handleToggle}>
                            <FaArrowRight />
                            Accueil
                        </Link>
                        <Link className="item" to="/connexion" onClick={handleToggle}>
                            <FaArrowRight />
                            Se connecter
                        </Link>
                        <Link className="item" to="/inscription" onClick={handleToggle}>
                            <FaArrowRight />
                            Inscription
                        </Link>
                        <Link className="item" to="/contact" onClick={handleToggle}>
                            <FaArrowRight />
                            Nous écrire
                        </Link>
                    </div >
                )
            }
        </div>
    )
}

export default HeaderMobile
