import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'proRdvSlice'

export const proRdvGetItem = createAsyncThunk(sliceName + "/proRdvGetItem", async (data, thunkAPI) => {
    try {

        return await axios.get(`manager-rdvs/RdvGetItem?IdRdv=${data.IdRdv}`);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const proRdvGetItems = createAsyncThunk(sliceName + "/proRdvGetItems", async (data, thunkAPI) => {
    try {

        return await axios.get(`manager-rdvs/RdvGetItems?from=${data.From}&to=${data.To}`);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const proRdvAddItem = createAsyncThunk(sliceName + "/proRdvAddItem", async (data, thunkAPI) => {
    try {

        return await axios.post(`manager-rdvs/RdvAddItem`);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const proRdvDeleteItem = createAsyncThunk(sliceName + "/proRdvDeleteItem", async (data, thunkAPI) => {
    try {

        await axios.delete(`manager-rdvs/RdvDeleteItem?IdRdv=${data.IdRdv}`);

        return thunkAPI.fulfillWithValue(data.IdRdv)

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const proRdvSaveItem = createAsyncThunk(sliceName + "/proRdvSaveItem", async (data, thunkAPI) => {
    try {

        return await axios.patch(`manager-rdvs/RdvSaveItem`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


export const proRdvDispoGetItems = createAsyncThunk(sliceName + "/proRdvDispoGetItems", async (data, thunkAPI) => {
    try {
        return await axios.get(`manager-rdvs/RdvHoraireDispoGetItems?date=${data.date}`);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


const initialState = {
    isLoading: false,
    rdv: null,
    rdvItems: null,

    isLoadingRdvDispoItems: false,
    rdvDispoItems: null,
};

export const proRdvSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        initDispoItems(state) {
            state.rdvDispoItems = null;
        },
    },
    extraReducers: (builder) => {

        // proRdvGetItem
        builder.addCase(proRdvGetItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(proRdvGetItem.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(proRdvGetItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.rdv = action.payload.data;
        });

        // proRdvGetItems
        builder.addCase(proRdvGetItems.pending, (state, action) => {
            state.isLoading = true;
            state.rdvItems = [];
        });
        builder.addCase(proRdvGetItems.rejected, (state, action) => {
            state.isLoading = false;
            state.rdvItems = [];
        });
        builder.addCase(proRdvGetItems.fulfilled, (state, action) => {
            state.isLoading = false;
            state.rdvItems = action.payload.data;
        });

        // proRdvAddItem
        builder.addCase(proRdvAddItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(proRdvAddItem.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(proRdvAddItem.fulfilled, (state, action) => {
            state.isLoading = false;
        });


        // proRdvDeleteItem
        builder.addCase(proRdvDeleteItem.fulfilled, (state, action) => {
            state.rdvItems = state.rdvItems?.filter(x => x.IdRdv !== action.payload.data);
        });


        // proRdvSaveItem
        builder.addCase(proRdvSaveItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(proRdvSaveItem.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(proRdvSaveItem.fulfilled, (state, action) => {
            state.isLoading = false;
        });


        // proRdvDispoGetItems
        builder.addCase(proRdvDispoGetItems.pending, (state, action) => {
            state.isLoadingRdvDispoItems = true;
        });
        builder.addCase(proRdvDispoGetItems.rejected, (state, action) => {
            state.isLoadingRdvDispoItems = false;
        });
        builder.addCase(proRdvDispoGetItems.fulfilled, (state, action) => {
            state.isLoadingRdvDispoItems = false;
            state.rdvDispoItems = action.payload.data;
        });


    }
});

// Actions
export const { initDispoItems } = proRdvSlice.actions

export const selectIsLoading = (state) => state.proRdvData.isLoading;
export const selectRdvItems = (state) => state.proRdvData.rdvItems;
export const selectRdv = (state) => state.proRdvData.rdv;


export const selectIsLoadingRdvDispoItems = (state) => state.proRdvData.isLoadingRdvDispoItems;
export const selectRdvDispoItems = (state) => state.proRdvData.rdvDispoItems;

export default proRdvSlice.reducer;