// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MKsVtxEDYZUJUGtLRxa9 {\r\n  background-color: white;\r\n  min-width: 0;\r\n  border: 1px solid #bfbfbf;\r\n  border-radius: 4px;\r\n  display: flex;\r\n  flex-grow: 1;\r\n  align-items: stretch;\r\n  overflow: hidden;\r\n}\r\n\r\n.MKsVtxEDYZUJUGtLRxa9.L7EXilvxVENB9WOkvGi7 {\r\n  background-color: #fafafa;\r\n}\r\n\r\n.MKsVtxEDYZUJUGtLRxa9 input {\r\n  outline: none;\r\n  border: none;\r\n  flex-grow: 1;\r\n  min-width: 0;\r\n}\r\n\r\n.MKsVtxEDYZUJUGtLRxa9.El84stE5mUUJ1DT5Rfip input {\r\n  text-align: center;\r\n}\r\n\r\n.MKsVtxEDYZUJUGtLRxa9:focus-within {\r\n  border: 1px solid #325288;\r\n}\r\n\r\n.MKsVtxEDYZUJUGtLRxa9 > span {\r\n  display: flex;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/InputText/InputText.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".input-text {\r\n  background-color: white;\r\n  min-width: 0;\r\n  border: 1px solid #bfbfbf;\r\n  border-radius: 4px;\r\n  display: flex;\r\n  flex-grow: 1;\r\n  align-items: stretch;\r\n  overflow: hidden;\r\n}\r\n\r\n.input-text.disabled {\r\n  background-color: #fafafa;\r\n}\r\n\r\n.input-text input {\r\n  outline: none;\r\n  border: none;\r\n  flex-grow: 1;\r\n  min-width: 0;\r\n}\r\n\r\n.input-text.centered input {\r\n  text-align: center;\r\n}\r\n\r\n.input-text:focus-within {\r\n  border: 1px solid #325288;\r\n}\r\n\r\n.input-text > span {\r\n  display: flex;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-text": "MKsVtxEDYZUJUGtLRxa9",
	"disabled": "L7EXilvxVENB9WOkvGi7",
	"centered": "El84stE5mUUJ1DT5Rfip"
};
export default ___CSS_LOADER_EXPORT___;
