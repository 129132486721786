import { useMediaQuery } from 'react-responsive'

export const isMaxTablet = () => useMediaQuery({ maxWidth: 1023 })

export const isMaxMobile = () => useMediaQuery({ maxWidth: 767 })

export const isMinDesktop = () => useMediaQuery({ minWidth: 1024 })

export const isMinTablet = () => useMediaQuery({ minWidth: 768 })

export const MaxTablet = ({ children }) => {
  const isMaxTablet = isMaxTablet()

  return isMaxTablet ? children : null
}

export const MaxMobile = ({ children }) => {
  const isMobile = isMaxMobile()

  return isMobile ? children : null
}

export const MinDesktop = ({ children }) => {
  const isMinDesktop = isMinDesktop()

  return isMinDesktop ? children : null
}

export const MinTablet = ({ children }) => {
  const isMinTablet = isMinTablet()

  return isMinTablet ? children : null
}
