import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'anonymousFicheSlice'

export const anonymousFicheGetItem = createAsyncThunk(sliceName + "/anonymousFicheGetItem", async (data, thunkAPI) => {
    try {
        return await axios.get(`fiches/FicheGetItem`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const anonymousFicheGetItems = createAsyncThunk(sliceName + "/anonymousFicheGetItems", async (data, thunkAPI) => {
    try {
        return await axios.get(`fiches/FicheGetItems`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


const initialState = {

    isLoading: false,
    isLoadingMore: false,

    fiche: null,

    searchData: null

};

export const anonymousFicheSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        // anonymousFicheGetItem
        builder.addCase(anonymousFicheGetItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(anonymousFicheGetItem.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(anonymousFicheGetItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.fiche = action.payload.data;
        });

        // anonymousFicheGetItems
        builder.addCase(anonymousFicheGetItems.pending, (state, action) => {

            const fromTmp = action?.meta?.arg?.params?.from || 0

            state.isLoading = fromTmp === 0 ? true : false;
            state.isLoadingMore = fromTmp === 0 ? false : true;
        });
        builder.addCase(anonymousFicheGetItems.rejected, (state, action) => {
            state.isLoading = false;
            state.isLoadingMore = false;
        });
        builder.addCase(anonymousFicheGetItems.fulfilled, (state, action) => {

            const fromTmp = action?.meta?.arg?.params?.from || 0

            const oldItems = [...(state.searchData?.Items || [])]

            state.isLoading = false;
            state.isLoadingMore = false;
            state.searchData = fromTmp === 0
                ? { ...(action.payload.data || []) }
                : {
                    CanLoadMore: action.payload.data.CanLoadMore,
                    Items: [
                        ...oldItems,
                        ...action.payload.data.Items
                    ]
                };
        });


    }
});


export const selectIsLoading = (state) => state.anonymousFicheData.isLoading;
export const selectIsLoadingMore = (state) => state.anonymousFicheData.isLoadingMore;

export const selectFicheItem = (state) => state.anonymousFicheData.fiche;
export const selectSearchData = (state) => state.anonymousFicheData.searchData;

export default anonymousFicheSlice.reducer;