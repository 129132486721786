import './BtnFloat.css'

import { Portal } from 'react-overlays'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const BtnFloat = ({ children, margin, onClick }) => {
  return (
    <Portal>
      <div className='btn-float' style={{ margin }} onClick={onClick}>
        {children}
      </div>
    </Portal>
  )
}

BtnFloat.defaultProps = {
  margin: '100px auto auto 100px',
}

BtnFloat.propTypes = {
  margin: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default BtnFloat
