import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'proFicheSlice'

export const proFicheGetItem = createAsyncThunk(sliceName + "/proFicheGetItem", async (data, thunkAPI) => {
    try {

        return await axios.get(`manager-fiches/FicheGetItem`);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const proFicheCopieSaveItem = createAsyncThunk(sliceName + "/proFicheCopieSaveItem", async (data, thunkAPI) => {
    try {

        return await axios.patch(`manager-fiches/FicheCopieSaveItem`, data);

    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})

export const proFicheOptionsGetItem = createAsyncThunk(sliceName + "/proFicheOptionsGetItem", async (data, thunkAPI) => {
    try {

        return await axios.get(`manager-fiches/FicheOptionsGetItem`, data);

    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})

export const proFicheOptionsSaveItem = createAsyncThunk(sliceName +"/proFicheOptionsSaveItem", async (data, thunkAPI) => {
    try {
        await axios.patch(`manager-fiches/FicheOptionsSaveItem`, data);

        return thunkAPI.fulfillWithValue(data);

    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})


const initialState = {

    isSaving:false,
    isLoading: false,
    ficheOptions : null,
    fiche: null,
};

export const proFicheSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: { },
    extraReducers: (builder) => {

        // proFicheGetItem
        builder.addCase(proFicheGetItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(proFicheGetItem.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(proFicheGetItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.fiche = action.payload.data;
        });

        // proFicheCopieSaveItem
        builder.addCase(proFicheCopieSaveItem.pending, (state, action) => {
            state.isSaving = true;
        });
        builder.addCase(proFicheCopieSaveItem.rejected, (state, action) => {
            state.isSaving = false;
        });
        builder.addCase(proFicheCopieSaveItem.fulfilled, (state, action) => {
            state.isSaving = false;
        });

        // ###### FICHE OPTION

        // proFicheOptionsGetItem
        builder.addCase(proFicheOptionsGetItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(proFicheOptionsGetItem.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(proFicheOptionsGetItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ficheOptions = action.payload.data;
        });

        // proFicheOptionsSaveItem
        builder.addCase(proFicheOptionsSaveItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(proFicheOptionsSaveItem.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(proFicheOptionsSaveItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ficheOptions = action.payload;
        });


    }
});


export const selectFiche = (state) => state.proFicheData.fiche;
export const selectIsLoading = (state) => state.proFicheData.isLoading;
export const selectIsSaving = (state) => state.proFicheData.isSaving;

export const selectFicheOptions = (state) => state.proFicheData.ficheOptions;

export default proFicheSlice.reducer;