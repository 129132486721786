// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Br409ef97C1BALk8IFDs {\r\n  visibility: hidden;\r\n  opacity: 0;\r\n  position: fixed;\r\n  top: 0;\r\n  bottom: 0;\r\n  left: 0;\r\n  right: 0;\r\n  background: rgba(0, 0, 0, 0.308);\r\n  transition: opacity 500ms;\r\n}\r\n.Gz9O4i7vquxFpaS9ezqY {\r\n  margin: 70px auto;\r\n  padding: 20px;\r\n  background: #fff;\r\n  border-radius: 5px;\r\n  width: 30%;\r\n  position: relative;\r\n  transition: all 5s ease-in-out;\r\n}\r\n\r\n.Gz9O4i7vquxFpaS9ezqY h2 {\r\n  margin-top: 0;\r\n  color: #333;\r\n  font-family: Tahoma, Arial, sans-serif;\r\n}\r\n.Gz9O4i7vquxFpaS9ezqY .iBwCcoEDh8Iw0FWyvsXD {\r\n  max-height: 30%;\r\n  overflow: auto;\r\n}\r\n\r\n@media screen and (max-width: 700px) {\r\n  .Gz9O4i7vquxFpaS9ezqY {\r\n    width: 70%;\r\n  }\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/Popin/Popin.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,eAAe;EACf,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,gCAAgC;EAChC,yBAAyB;AAC3B;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,WAAW;EACX,sCAAsC;AACxC;AACA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".overlay {\r\n  visibility: hidden;\r\n  opacity: 0;\r\n  position: fixed;\r\n  top: 0;\r\n  bottom: 0;\r\n  left: 0;\r\n  right: 0;\r\n  background: rgba(0, 0, 0, 0.308);\r\n  transition: opacity 500ms;\r\n}\r\n.popup {\r\n  margin: 70px auto;\r\n  padding: 20px;\r\n  background: #fff;\r\n  border-radius: 5px;\r\n  width: 30%;\r\n  position: relative;\r\n  transition: all 5s ease-in-out;\r\n}\r\n\r\n.popup h2 {\r\n  margin-top: 0;\r\n  color: #333;\r\n  font-family: Tahoma, Arial, sans-serif;\r\n}\r\n.popup .content {\r\n  max-height: 30%;\r\n  overflow: auto;\r\n}\r\n\r\n@media screen and (max-width: 700px) {\r\n  .popup {\r\n    width: 70%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "Br409ef97C1BALk8IFDs",
	"popup": "Gz9O4i7vquxFpaS9ezqY",
	"content": "iBwCcoEDh8Iw0FWyvsXD"
};
export default ___CSS_LOADER_EXPORT___;
