// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t20Ba8yecbce4yCIFyqd {\r\n  display: flex;\r\n  flex-direction: column;\r\n  background-color: white;\r\n}\r\n\r\n.t20Ba8yecbce4yCIFyqd .Q510I_4UAD1MNWaX9Ek3 {\r\n  display: flex;\r\n  align-items: stretch;\r\n  border-bottom: 1px solid #dfdbdb;\r\n  border-top: 1px solid #dfdbdb;\r\n  cursor: pointer;\r\n  user-select: none;\r\n  background-color: #f5f5f5;\r\n}\r\n\r\n.t20Ba8yecbce4yCIFyqd .Q510I_4UAD1MNWaX9Ek3 .MCRBxa5MTrLMRbRt_53A {\r\n  display: flex;\r\n  align-items: stretch;\r\n  flex-grow: 1;\r\n}\r\n\r\n.t20Ba8yecbce4yCIFyqd .Q510I_4UAD1MNWaX9Ek3 svg {\r\n  font-size: 1.4rem;\r\n  color: #325288;\r\n  margin: auto;\r\n  padding-right: 6px;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/Collapsible/Collapsible.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,gCAAgC;EAChC,6BAA6B;EAC7B,eAAe;EACf,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".collapsible {\r\n  display: flex;\r\n  flex-direction: column;\r\n  background-color: white;\r\n}\r\n\r\n.collapsible .header {\r\n  display: flex;\r\n  align-items: stretch;\r\n  border-bottom: 1px solid #dfdbdb;\r\n  border-top: 1px solid #dfdbdb;\r\n  cursor: pointer;\r\n  user-select: none;\r\n  background-color: #f5f5f5;\r\n}\r\n\r\n.collapsible .header .title {\r\n  display: flex;\r\n  align-items: stretch;\r\n  flex-grow: 1;\r\n}\r\n\r\n.collapsible .header svg {\r\n  font-size: 1.4rem;\r\n  color: #325288;\r\n  margin: auto;\r\n  padding-right: 6px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapsible": "t20Ba8yecbce4yCIFyqd",
	"header": "Q510I_4UAD1MNWaX9Ek3",
	"title": "MCRBxa5MTrLMRbRt_53A"
};
export default ___CSS_LOADER_EXPORT___;
