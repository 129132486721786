
/*############    VENDOR    ##########*/

import Config from '../Config'
import ReactGA from 'react-ga4'
export const Init = function () {

    if (!Config.IS_DEBUG) {

        ReactGA.initialize(Config.GOOGLE_ANALYTICS)

    } else {
        ReactGA.initialize('test', {
            debug: true
        });
    }
}

export const SendPageChange = function (paramPath) {
    if (!Config.IS_DEBUG) {

        ReactGA.send({ hitType: "pageview", page: paramPath });

    } else {
        //console.log("GA ---- NEW_PAGE", paramPath);
    }
}

export const SendUserId = function (paramEmail, paramUserId) {
    if (!Config.IS_DEBUG) {

        ReactGA.set({ userId: paramUserId })

        ReactGA.gtag("set", "user_properties", {
            user_algerie_docto: paramUserId,
        })

    } else {
        //console.log("GA ---- SEND_USER_ID", paramUserId);
    }
}

export const SendEvent = function (paramCat, paramAct, paramLabel) {
    if (!Config.IS_DEBUG) {
        ReactGA.event({
            category: paramCat,
            action: paramAct,
            label: paramLabel
        });
    } else {
        //console.log("GA ---- SEND_EVENT : " + paramCat + ' - ' + paramAct + ' - ' + paramLabel)
    }
}


