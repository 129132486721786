
import React from 'react';
import './ErrorBoundary.css';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };

    }

    static getDerivedStateFromError(error) {
        console.log("error", error);
        // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.
        //logErrorToMyService(error, errorInfo);
        console.log("error", error);
        console.log("errorInfo", errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-boundary">
                    <br />
                    <br />
                    <br />
                    <object data="/images/error.svg" width="600" height="300"> </object>
                    <br />
                    <h1 className="title">Erreur lors du traitement de votre demande :( </h1>
                    <br />
                    <h2 className="title">Veuillez nous excuser pour la gêne occassionnée...</h2>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary

