

const Config = {
    GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS,
    GOOGLE_RECAPTCHA_PUBLIC: process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC,
    INSCRIPTION_URL: process.env.REACT_APP_INSCRIPTION_URL,
    IS_DEBUG: process.env.REACT_APP_IS_DEBUG === 'true',
};


export default Config