
import { BiLogOut } from '@react-icons/all-files/bi/BiLogOut';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { selectUserProfile } from '../../../slices/anonymous/anonymousAuthSlice';
import './MenuAdmin.css';

const MenuAdmin = () => {

    const userProfile = useSelector(selectUserProfile)

    const parsed = dayjs(userProfile?.DerniereConnexion)

    return (
        <div className="menu-admin">
            <Link to="/" className="image-container">
                <img loading="lazy" className="logo" src="/images/logo.png" alt="logo algerie-docto docto pro" />
            </Link>
            <div className="acces">
                <span>Dernier accès</span>
                <span>{parsed.format('DD/MM/YYYY HH:mm')}</span>
            </div>
            <Link to="/connexion" className="disconnet">
                <BiLogOut />
                Se déconnecter
            </Link>
            <NavLink to="/manager/admin/accueil" className="menu-item">
                Accueil
            </NavLink>
            <NavLink to="/manager/admin/email-conf" className="menu-item">
                Email Conf
            </NavLink>
            <NavLink to="/manager/admin/email-template" className="menu-item">
                Email Templates
            </NavLink>
            <NavLink to="/manager/admin/email-send" className="menu-item">
                Email Send
            </NavLink>
            <NavLink to="/manager/admin/email-history" className="menu-item">
                Email History
            </NavLink>
            <br />
            <br />
        </div>
    )
}

export default MenuAdmin