import './PopoverMenu.css'

import { default as Btn, BtnVariants } from '../Btn'

import { AiFillCaretDown } from '@react-icons/all-files/ai/AiFillCaretDown'
import Popover from '../Popover'

const PopoverMenu = ({ children, isVisible, text, onChangeVisibility }) => {
  return (
    <Popover isVisible={isVisible} onHide={() => onChangeVisibility(false)} placement='bottom'>
      <div className='popover-menu-button'>
        <Btn onClick={() => onChangeVisibility(!isVisible)} variant={BtnVariants.Secondary}>
          <div>{text}</div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <AiFillCaretDown size='22' />
        </Btn>
      </div>

      <div className='popover-menu'>
        {children.map((item, index) => {
          return (
            <div key={'popover-menu' + index} className='popup-menu-item' onClick={() => item.action()}>
              {item.value}
            </div>
          )
        })}
      </div>
    </Popover>
  )
}

export default PopoverMenu
