import classNames from "classnames";
import "./Page.css";

const Page = ({ children, className }) => {
  const compClass = classNames("page", {
    [className]: className,
  });

  return <div className={compClass}>{children}</div>;
};

export default Page;
