import "./Image.css";

const Image = ({ url, color, width, height }) => {
  if (!url || url === "")
    return (
      <div
        className="image"
        style={{ backgroundColor: color, width, height }}
      ></div>
    );
  else return <img className="image" src={url} style={{ width, height }} />;
};

export default Image;
