import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'proUserSlice'

export const proUserMdpUpdateItem = createAsyncThunk(sliceName + "/proUserMdpUpdateItem", async (data, thunkAPI) => {
    try {

        return await axios.post(`manager-auth/UserMdpUpdateItem`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


const initialState = {
    isLoading: false,
    isSuccess: false,
    error: null
};

export const proUserSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        initPro(state) {
            state.isSuccess = false;
        }
    },
    extraReducers: (builder) => {

        // proUserMdpUpdateItem
        builder.addCase(proUserMdpUpdateItem.pending, (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.error = null;
        });
        builder.addCase(proUserMdpUpdateItem.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.error = action.payload;
        });
        builder.addCase(proUserMdpUpdateItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
        });


    }
});

// Actions
export const { initPro } = proUserSlice.actions

export const selectIsLoadingPro = (state) => state.proUserData.isLoading;
export const selectIsSuccessPro = (state) => state.proUserData.isSuccess;
export const selectErrorPro = (state) => state.proUserData.error;

export default proUserSlice.reducer;