import './Table.css'

import classNames from 'classnames'

const Table = ({ children, className }) => {
  const componentClass = classNames('table', className)

  return <div className={componentClass}>{children}</div>
}

export default Table
