import './TableContent.css'

const TableContent = ({ children, minHeight, maxHeight }) => {
  return (
    <div className='table-content' style={{ minHeight, maxHeight }}>
      {children}
    </div>
  )
}

export default TableContent
