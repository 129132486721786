/*############    VENDOR    ##########*/

/*############    APP       ##########*/
import "./PageSummary.css";

const PageSummary = ({ children, template }) => {
  return (
    <div className="page-summary" style={{ gridTemplateColumns: template }}>
      {children}
    </div>
  );
};

export default PageSummary;
