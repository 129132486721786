import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/Axios'

const sliceName = 'anonymousFichierSlice'

export const anonymousFichierGetItems = createAsyncThunk(sliceName + "/anonymousFichierGetItems", async (data, thunkAPI) => {
    try {
        return await axios.get(`fichiers/FichierGetItems`, data);

    } catch (error) {
        if (!error.response) {
            throw error
        }

        return thunkAPI.rejectWithValue(error.response.data);
    }
})


const initialState = {
    isLoading: false,
    fichierItems: null,
};

export const anonymousFichierSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: { },
    extraReducers: (builder) => {

        // anonymousFichierGetItems
        builder.addCase(anonymousFichierGetItems.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(anonymousFichierGetItems.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(anonymousFichierGetItems.fulfilled, (state, action) => {
            state.isLoading = false;
            state.fichierItems = action.payload.data;
        });

    }
});


export const selectIsLoading = (state) => state.anonymousFichierData.isLoading;

export const selectFichierItems = (state) => state.anonymousFichierData.fichierItems;

export default anonymousFichierSlice.reducer;