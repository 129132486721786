import { useMobileQuery } from '../../../utils/MediaQueries'
import { HeaderBottom } from '../HeaderBottom'
import { HeaderTop } from '../HeaderTop'
import { HeaderMobile } from '../HeaderMobile'
import './Header.css'

export const Header = ({ }) => {

    const isMobile = useMobileQuery()

    if (isMobile) {
        return (
            <div className="header-mobile">
                <HeaderMobile />
            </div>

        )
    }

    return (
        <div className="header-desktop">
            <HeaderTop />
            <HeaderBottom />
        </div>
    )
}

export default Header
