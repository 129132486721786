import React, { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/commons/ScrollToTop'
import { SendPageChange } from './utils/AnalyticsUtils'
import { history } from './configureStore'

// #####################################
// #######     LAYOUT     ##############
// #####################################

import LayoutPage from './layouts/LayoutPage'
import LayoutManager from './layouts/LayoutManager/LayoutManager'
import LayoutUser from './layouts/LayoutUser/LayoutUser'
import LayoutAdmin from './layouts/LayoutAdmin/LayoutAdmin'

import CustomRouter from './CustomRouter';


// #####################################
// #######     INFO      ###############
// #####################################

const PageCarteChifa = lazy(() => import('./pages/infos/PageCarteChifa'))


// #####################################
// #######     USER     ################
// #####################################

// Auth
const PageAccueil = lazy(() => import('./pages/PageAccueil/PageAccueil'))
const PageNotFound = lazy(() => import('./pages/PageNotFound/PageNotFound'))
const PageUnder = lazy(() => import('./pages/PageUnder'))
const PageContact = lazy(() => import('./pages/PageContact/PageContact'))
const PageConditions = lazy(() => import('./pages/PageConditions'))
const PageInscription = lazy(() => import('./pages/PageInscription'))
const PageInscriptionPro = lazy(() => import('./pages/PageInscriptionPro'))
const PageLoginUser = lazy(() => import('./pages/PageLoginUser'))

// Type
const PageTypeWilaya = lazy(() => import('./pages/type/PageTypeWilaya/PageTypeWilaya'))
const PageTypeMedecin = lazy(() => import('./pages/type/PageTypeMedecin/PageTypeMedecin'))
const PageTypeEtablissement = lazy(() => import('./pages/type/PageTypeEtablissement/PageTypeEtablissement'))
const PageTypeService = lazy(() => import('./pages/type/PageTypeService'))

// Fiche
const PageFicheConsultation = lazy(() => import('./pages/PageFicheConsultation/PageFicheConsultation'))

// Search
const PageSearch = lazy(() => import('./pages/search/PageSearch'))
const PageSearchWilayaSpecialite = lazy(() => import('./pages/search/PageSearchWilayaSpecialite'))
const PageSearchWilayaEtablissement = lazy(() => import('./pages/search/PageSearchWilayaEtablissement'))
const PageSearchWilayaService = lazy(() => import('./pages/search/PageSearchWilayaService'))

// Recovery
const PageRecoveryRequest = lazy(() => import('./pages/recovery/PageRecoveryRequest/PageRecoveryRequest'))
const PageRecoveryValidation = lazy(() => import('./pages/recovery/PageRecoveryValidation'))


// #####################################
// #######     PRO   ################
// #####################################

// Manager ACCUEIL
const PageManAccueil = lazy(() => import('./pages-manager/PageManAccueil/PageManAccueil'))

// Manager FICHE
const PageManFicheEdit = lazy(() => import('./pages-manager/PageManFicheEdit'))

// Manager Patient
const PageManPatientEdit = lazy(() => import('./pages-manager/PageManPatientEdit'))
const PageManPatientList = lazy(() => import('./pages-manager/PageManPatientList'))

// Manager RDV
const PageManRdvList = lazy(() => import('./pages-manager/PageManRdvList'))
const PageManRdvEdit = lazy(() => import('./pages-manager/PageManRdvEdit'))

// Planning
const PageManPlanning = lazy(() => import('./pages-manager/PageManPlanning'))

// Compte
const PageManParametres = lazy(() => import('./pages-manager/PageManParametres'))



// #####################################
// #######     Patient   ###############
// #####################################

const PagePatientAccueil = lazy(() => import('./pages-patient/PagePatientAccueil'))
const PagePatientParametres = lazy(() => import('./pages-patient/PagePatientParametres'))


// #####################################
// #######     ADMIN     ###############
// #####################################

const PageAdminAccueil = lazy(() => import('./pages-admin/PageAdminAccueil'))
const PageAdminEmailConf = lazy(() => import('./pages-admin/PageAdminEmailConf'))
const PageAdminEmailTemplate = lazy(() => import('./pages-admin/PageAdminEmailTemplate'))
const PageAdminEmailSend = lazy(() => import('./pages-admin/PageAdminEmailSend'))
const PageAdminEmailHistory = lazy(() => import('./pages-admin/PageAdminEmailHistory'))


const isLoading = '...'

class MyRouter extends React.Component {
    render() {
        return (
            <CustomRouter history={history}>
                <Suspense fallback={isLoading}>
                    <ScrollToTop>
                        <Routes>

                            <Route path="/manager/patient" element={<LayoutUser />}>
                                <Route path="accueil" element={<PagePatientAccueil />} />
                                <Route path="parametres" element={<PagePatientParametres />} />
                            </Route>

                            <Route path="/manager/pro" element={<LayoutManager />}>

                                <Route path="accueil" element={<PageManAccueil />} />

                                <Route path="patients/:idPatient" element={<PageManPatientEdit />} />
                                <Route path="patients" element={<PageManPatientList />} />

                                <Route path="fiche-edit/:i" element={<PageManFicheEdit />} />

                                <Route path="rendez-vous-edit/:i" element={<PageManRdvEdit />} />
                                <Route path="rendez-vous" element={<PageManRdvList />} />

                                <Route path="planning" element={<PageManPlanning />} />

                                <Route path="parametres" element={<PageManParametres />} />

                            </Route>

                            <Route path="/manager/admin" element={<LayoutAdmin />}>
                                <Route path="accueil" element={<PageAdminAccueil />} />
                                <Route path="email-conf" element={<PageAdminEmailConf />} />
                                <Route path="email-template" element={<PageAdminEmailTemplate />} />
                                <Route path="email-send" element={<PageAdminEmailSend />} />
                                <Route path="email-history" element={<PageAdminEmailHistory />} />
                            </Route>

                            <Route path="/" element={<LayoutPage />}>

                                <Route index element={<PageAccueil />} />
                                <Route path="contact" element={<PageContact />} />
                                <Route path="conditions" element={<PageConditions />} />
                                <Route path="under" element={<PageUnder />} />
                                <Route path="inscription-pro" element={<PageInscriptionPro />} />
                                <Route path="inscription" element={<PageInscription />} />
                                <Route path="connexion" element={<PageLoginUser />} />

                                <Route path="search" element={<PageSearch />} />
                                <Route path="search-wilaya-specialite/:w/:s/*" element={<PageSearchWilayaSpecialite />} />
                                <Route path="search-wilaya-etablissement/:w/:e/*" element={<PageSearchWilayaEtablissement />} />
                                <Route path="search-wilaya-service/:w/:s/*" element={<PageSearchWilayaService />} />

                                <Route path="wilaya/:i/:j/*" element={<PageTypeWilaya />} />
                                <Route path="medecin/type/:i/*" element={<PageTypeMedecin />} />
                                <Route path="etablissement/type/:i/*" element={<PageTypeEtablissement />} />
                                <Route path="service/type/:i/*" element={<PageTypeService />} />

                                <Route exact path="medecin/:i/:t/:l/*" element={<PageFicheConsultation />} />
                                <Route exact path="etablissement/:i/:t/:l/*" element={<PageFicheConsultation />} />
                                <Route exact path="service/:i/:t/:l/*" element={<PageFicheConsultation />} />

                                <Route exact path="recovery/request" element={<PageRecoveryRequest />} />
                                <Route exact path="recovery/submit/:i/:t/*" element={<PageRecoveryValidation />} />



                                <Route exact path="infos/carte-chifa" element={<PageCarteChifa />} />
                            </Route>

                            <Route path="/" element={<LayoutPage />}>
                                <Route path="*" element={<PageNotFound />} />
                            </Route>

                        </Routes>
                    </ScrollToTop>
                </Suspense>
            </CustomRouter>
        )
    }
}

export default MyRouter
